import { createContext } from "react";
import lightTheme from "../themes/light";

interface ThemeContextDto {
	toggleTheme(theme: typeof lightTheme): void;
	theme: typeof lightTheme;
}

const CustomThemeContext = createContext<ThemeContextDto>(
	{} as ThemeContextDto
);

export default CustomThemeContext;
