import React from "react";
import {
	HeaderContainer,
	HeaderHead,
	HeaderContent,
	HeaderMenuContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { FaChartBar, FaHeadset, FaComment, FaAdjust } from "react-icons/fa";
import AuthProvider from "../../provider/AuthProvider";
import TabMenu from "./components/TabMenu";
import DefaultButton from "../DefaultButton";
import { IUsuarioSimplificado } from "../../dto/UsuarioSimplificado";
import UserProvider from "../../provider/UserProvider";
import UserCard from "./components/UserCard";
import PermissionValidator from "../../guard/PermissionValidator";
import { PERMISSIONS } from "../../guard/Permissions";

function Header(): JSX.Element {
	const [user, setUser] = React.useState<IUsuarioSimplificado>(
		{} as IUsuarioSimplificado
	);
	const authProvider = new AuthProvider();
	const userProvider = new UserProvider();
	const permissionValidator = new PermissionValidator();

	const navigate = useNavigate();

	async function handleLogout() {
		try {
			await authProvider.logout();
			navigate("/entrar");
		} catch (error) {
			console.error("Erro ao fazer logout:", error);
		}
	}

	async function getUser() {
		try {
			if (!permissionValidator.hasPermission(PERMISSIONS.VISUALIZAR_PERFIL))
				return;
			const user = await userProvider.getOwnProfile();
			setUser(user);
		} catch (error) {
			console.error("Erro ao buscar usuário:", error);
		}
	}

	function updateSticky() {
		const stickyElement = document.getElementById("nav-tab-menu");
		let placeholder = document.getElementById("placeholder");

		if (!stickyElement) return;

		if (window.scrollY > stickyElement.offsetTop) {
			if (!placeholder) {
				placeholder = document.createElement("div");
				placeholder.id = "placeholder";
				if (stickyElement.parentNode)
					stickyElement.parentNode.insertBefore(placeholder, stickyElement);
			}
			placeholder.style.height = `${stickyElement.offsetHeight}px`;
			stickyElement.style.position = "fixed";
			stickyElement.style.top = "0";
			stickyElement.style.right = "0";
			stickyElement.style.left = "0";
		} else {
			if (placeholder && placeholder.parentNode) {
				placeholder.parentNode.removeChild(placeholder);
			}
			stickyElement.style.position = "sticky";
			stickyElement.style.top = "0";
		}
	}

	React.useEffect(() => {
		window.addEventListener("scroll", updateSticky);
		getUser();

		return () => {
			window.removeEventListener("scroll", updateSticky);
		};
	}, []);

	const [currentTenant, setCurrentTenant] = React.useState<string>("");
	const [hasMultipleProfiles, setHasMultipleProfiles] =
		React.useState<boolean>(false);

	React.useEffect(() => {
		const tenant = localStorage.getItem("tenantName");
		if (tenant) setCurrentTenant(tenant);
	}, [window.localStorage.getItem("tenantName")]);

	React.useEffect(() => {
		const multipleProfiles = localStorage.getItem("multipleProfiles");
		if (multipleProfiles) setHasMultipleProfiles(multipleProfiles === "true");
	}, [window.localStorage.getItem("multipleProfiles")]);

	return (
		<HeaderContainer>
			<HeaderHead>
				<div className="header-head-left">
					<div className="header-gov-logo" onClick={() => window.location.href = "/" }/>
				</div>

				<div className="header-head-right">
					<ul className="header-accessibility">
						<li>
							<FaChartBar />
						</li>
						<li>
							<FaHeadset />
						</li>
						<li>
							<FaComment />
						</li>
						<li>
							<FaAdjust />
						</li>
					</ul>
					<span onClick={handleLogout}>Sair</span>
				</div>
			</HeaderHead>

			<HeaderContent>
				<section>
					{/* <button
						type="button"
						className="header-side-menu-button"
						onClick={() => setOpen(!open)}
					>
						<FaBars title="Abrir menu" />
					</button>
					<SideMenu
						open={open}
						setOpen={setOpen}
					/> */}

					<div className="header-title">
						<h2>Ideia X</h2>
						<p>Plataforma de inovação</p>
					</div>

					{user &&
						user.name &&
						permissionValidator.hasPermission(
							PERMISSIONS.VISUALIZAR_PERFIL
						) && <UserCard user={user} />}
				</section>
			</HeaderContent>
			<HeaderMenuContainer>
				<TabMenu />
				<section>
					{currentTenant &&
						currentTenant !== null &&
						currentTenant !== "undefined" && (
							<>
								<span>{currentTenant ? currentTenant : ""}</span>
								{hasMultipleProfiles && (
									<DefaultButton
										width="150px"
										height="35px"
										fontSize="1em"
										withOutBackground={true}
										params={{
											onClick: () => {
												localStorage.removeItem("tenantName");
												window.location.reload();
											},
										}}
									>
										Trocar perfíl
									</DefaultButton>
								)}
							</>
						)}
				</section>
			</HeaderMenuContainer>
		</HeaderContainer>
	);
}

export default Header;
