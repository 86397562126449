function sortList<T>(
	list: T[],
	key: keyof T,
	order: "asc" | "desc" = "asc"
): T[] {
	return list.sort((a, b) => {
		const valueA = a[key];
		const valueB = b[key];

		const dateA = valueA instanceof Date ? valueA : new Date(valueA as any);
		const dateB = valueB instanceof Date ? valueB : new Date(valueB as any);

		if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
			// Comparar datas
			if (dateA < dateB) {
				return order === "asc" ? -1 : 1;
			}
			if (dateA > dateB) {
				return order === "asc" ? 1 : -1;
			}
			return 0;
		}

		// Comparar outros tipos (strings, números, etc.)
		if (valueA < valueB) {
			return order === "asc" ? -1 : 1;
		}
		if (valueA > valueB) {
			return order === "asc" ? 1 : -1;
		}
		return 0;
	});
}

export default sortList;
