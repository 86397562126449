import React from "react";
import { ExcluirRoleContainer } from "./styles";
import Modal from "../../../../shared/util/Modal";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { FaUserTimes } from "react-icons/fa";
import { OptionValue } from "../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../shared/provider/RoleProvider";

function DeleteRole({
	open,
	setOpen,
	role,
	onFinished,
}: {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	role: OptionValue;
	onFinished?: () => void;
}) {
	const [successMessage, setSuccessMessage] = React.useState("");
	const roleProvider = new RoleProvider();
	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	async function handleExcluirRole() {
		if (role) {
			try {
				await roleProvider.delete(role.value);
				setSuccessMessage("Grupo de permissão excluido com sucesso.");
			} catch (error) {
				console.error("Erro ao excluir perfil de acesso:", error);
			}
			return;
		}
	}

	return (
		<ExcluirRoleContainer>
			<Modal
				open={open}
				setOpen={setOpen}
			>
				{!successMessage && (
					<>
						<h3
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
                        >
							Apagar Perfíl
						</h3>
						<p
							style={{
								textAlign: "center",
							}}
						>
							O perfíl <b>"{role.label}"</b> será apagado se não houver usuários
							vinculados. Esta operação não pode ser desfeita. Confirmar a ação?
						</p>

						<footer>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: handleExcluirRole,
								}}
							>
								Apagar
							</DefaultButton>

							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Voltar
							</DefaultButton>
						</footer>
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<h2 style={{textAlign: "center"}}>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => {
										setOpen(false);
										onFinished && onFinished();
									},
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</ExcluirRoleContainer>
	);
}

export default DeleteRole;
