import { DefaultButtonContainer } from "./styles";

interface IDefaultButtonProps {
	children: React.ReactNode;
	params?: any;
	withOutBackground?: boolean;
	marginTop?: string;
	width?: string;
	height?: string;
	fontSize?: string;
	padding?: string;
}

function DefaultButton(props: IDefaultButtonProps): JSX.Element {
	return (
		<DefaultButtonContainer
			withOutBackground={props.withOutBackground}
			marginTop={props.marginTop}
			width={props.width}
			height={props.height}
			fontSize={props.fontSize}
			padding={props.padding}
			{...props.params}
		>
			{props.children}
		</DefaultButtonContainer>
	);
}
export default DefaultButton;