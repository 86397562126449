import { FaTrash } from "react-icons/fa";
import { StageDto } from "../../../../../shared/dto/StageDto";
import { StageItem } from "../../../../../shared/dto/StageItem";
import { Tooltip } from "antd";
import generateUUID from "../../../../../shared/constants/uuid";

interface MapProps {
  dto: StageDto[];
  deleteFn: (stage: StageDto) => void;
  viewFn: (stage: StageDto) => void;
}

function mapStageDtoListToItems({ dto, deleteFn, viewFn } : MapProps): StageItem[] {
  return dto.map((item) => ({
    ...item,
    key: generateUUID(),
    action: <>
    <Tooltip title="Excluir Fase">
      <span
        className="list-delete-button"
        onClick={() => deleteFn(item)}
      >
        <FaTrash />
      </span>
    </Tooltip>
    <span
      className="list-editar-button"
      onClick={() => viewFn(item)}
    >
      Editar
    </span>
    </>
  }));
}

export default mapStageDtoListToItems;