async function cropImageFromUrl(url: string, x: number, y: number, width: number, height: number): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  const bitmap = await createImageBitmap(blob);
  
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  
  if (!context) throw new Error("Unable to get canvas context");
  context.drawImage(bitmap, x, y, width, height, 0, 0, width, height);
  
  const croppedBlob = await new Promise<Blob | null>(resolve => {
      canvas.toBlob(blob => {
          resolve(blob);
      }, 'image/png');
  });

  if (!croppedBlob) throw new Error("Unable to create blob from canvas");

  const croppedFile = new File([croppedBlob], 'cropped.png', { type: 'image/png' });
  
  return croppedFile;
}

export async function cropImageFromFile(file: File, x: number, y: number, width: number, height: number): Promise<File> {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
          if (event.target?.result) {
              const image = new Image();
              image.onload = async () => {
                  const canvas = document.createElement('canvas');
                  canvas.width = width;
                  canvas.height = height;
                  const context = canvas.getContext('2d');
                  if (context) {
                      context.drawImage(image, x, y, width, height, 0, 0, width, height);
                      const croppedBlob = await new Promise<Blob | null>(resolve => {
                          canvas.toBlob(blob => {
                              resolve(blob);
                          }, 'image/jpeg');
                      });

                      if (!croppedBlob) throw new Error("Unable to create blob from canvas");

                      const croppedFile = new File([croppedBlob], 'cropped.jpg', { type: 'image/jpeg' });
                      resolve(croppedFile);
                  }
              };
              image.src = event.target.result as string;
          }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
  });
}