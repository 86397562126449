import styled from "styled-components";

export const AdicionarRoleContainer = styled.div`
    transition: all 0.5s ease-in-out;

    header {
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 1.5em;
            margin-bottom: 20px;
        }
    }

    .edit-role-success-step {
        text-align: center;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 15px;
        }
    }

    .adicionar-role-modal-container {
        max-width: 700px;
        width: 100%;
        border-radius: 15px; /* Adiciona bordas arredondadas */
        padding: 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        background-color: #fff;

        
        @media (max-width: 768px) {
            max-width: 90%;
        }

        @media (max-width: 480px) {
            max-width: 100%;
            padding: 15px;
        }
    }
`;
