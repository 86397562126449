import { toast } from 'react-toastify';
import { CreateRoleDto } from '../dto/CreateRoleDto';
import { RoleOptionValue as OptionValue } from '../dto/OptionValue';
import { IPageData } from '../dto/PageData';
import { RoleDto } from '../dto/RoleDto';
import { UpdateRoleDto } from '../dto/UpdateRoleDto';
import Envoy from '../util/Envoy';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
  orderBy?: string
  direction?: 'asc' | 'desc'
}

class RoleProvider extends Envoy {
  public async getAllWithPagination({
    page,
    itemsPerPage,
    orderBy,
    direction
  } : IPaginationDTO): Promise<IPageData<Array<OptionValue>>> {
    try {
      const paginationString = this.buildQueryString({page, itemsPerPage, orderBy, direction});

      const response = await this.conn.get(`/auth/role${paginationString}`);
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter todas as funções:', error);
      throw error;
    }
  }

  public async getAll(): Promise<IPageData<Array<OptionValue>>> {
    try {
      const response = await this.conn.get('/auth/role');
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter todas as funções:', error);
      throw error;
    }
  }

  public async getPermissionOptions(): Promise<Array<OptionValue>> {
    try {
      const response = await this.conn.get('/auth/role/permission-options');
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter as opções de permissão:', error);
      throw error;
    }
  }

  public async createRole(roleData: CreateRoleDto): Promise<string> {
    try {
        const response = await this.conn.post('/auth/role', roleData);
        
        if (response.data.messages && response.data.messages.length)
            throw new Error(response.data.messages.join('\n'));
        if (response.data.data) return response.data.data.name;
        return "";
    } catch (error) {
        console.error('Erro ao criar o papel:', error);
        throw error;
    }
  }

  public async get(roleId: string): Promise<RoleDto> {
    try {
      const response = await this.conn.get(`/auth/role/${roleId}`);
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter a função:', error);
      throw error;
    }
  }

  public async update(roleId: string, roleData: UpdateRoleDto): Promise<string> {
    try {
      const response = await this.conn.put(`/auth/role/${roleId}`, roleData);
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      if (response.data.data) return response.data.data.name;
      return "";
    } catch (error) {
      console.error('Erro ao atualizar a função:', error);
      throw error;
    }
  }

  public async delete(roleId: string): Promise<boolean> {
    try {
      const response = await this.conn.delete(`/auth/role/${roleId}`);
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return true
    } catch (error: any) {
      if (error && error.response.data.messages.length && error.response.data.messages.find((message: string) => message.includes('Existem usuários com esse perfil de acesso.'))) {
        toast.error('Existem usuários com esse perfil de acesso.');
      }
      console.error('Erro ao excluir a função:', error);
      throw error;
    }
  }
}

export default RoleProvider;