import React from "react";
import { MinhasIdeiasContainer } from "./styles";
import { Avatar, Card, Divider, Flex, List, Skeleton, Space, Tag } from "antd";
import InfiniteScroll from 'react-infinite-scroll-component';
import { LikeOutlined, MessageOutlined, DislikeOutlined } from '@ant-design/icons';
import { useIdeia } from "../../../../../../../../context/ideia/provider";
import { IdeiaDto } from "../../../../../../../../dto/IdeiaDto";

function MinhasIdeias() {
  const [loading, setLoading] = React.useState(false);
  const { ideias, loadIdeias, page, itemsPerPage, setPage, totalItems, setOpenView, setIdeia } = useIdeia();

  const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space style={{cursor: 'pointer'}}>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  function handlePagination() {
    loadIdeias({
      page: page + 1,
      itemsPerPage,
    })
    setPage(page + 1);
  }

  function handleOpen(ideia: IdeiaDto) {
    setIdeia(ideia)
    setOpenView(true);
  }

  React.useEffect(() => {
    loadIdeias({
      page: 1,
      itemsPerPage: 10,
    });
  } ,[]);

  return (
    <MinhasIdeiasContainer
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={ideias.length}
        next={handlePagination}
        hasMore={ideias.length < totalItems}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>Todas as idéias do usuário já foram carregadas</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={ideias}
          renderItem={(item) => (
            <List.Item
              key={item.ideaId}
              actions={[
                <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                <IconText icon={DislikeOutlined} text="16" key="list-vertical-star-o" />,
                <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
              ]}
              >
              <List.Item.Meta
                title={<h4 style={{ cursor: 'pointer' }} onClick={() => handleOpen(item)}>{item.title}</h4>}
                description={<Flex gap={10}><Tag color="blue">{item.category?.name}</Tag><Tag color="cyan">Fase mockada</Tag></Flex>}
              />
              {item.shortDescription}
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </MinhasIdeiasContainer>
  );
}

export default MinhasIdeias;