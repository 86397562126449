import React from "react";
import { EditarStageContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import EditStageForm from "./components/editStageForm";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { StageDto } from "../../../../../../shared/dto/StageDto";

function EditStage({
	open,
	setOpen,
	handleDeleteStageClick,
	requireStageList,
	stage,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	handleDeleteStageClick: (stage: StageDto) => void;
	requireStageList(): Promise<void>;
	stage: StageDto;
}>) {
	const [loading, setLoading] = React.useState(false);
	const [successMesstage, setSuccessMesstage] = React.useState("");
	const [editMode, setEditMode] = React.useState(true);

	const isEditMode = editMode ? <h2>Editar Fase da Ideia</h2> : <h2>Fase da Ideia</h2>;

	React.useEffect(() => {
		if (open) {
			setEditMode(true);
			setSuccessMesstage("");
		}
	}, [open]);

	React.useEffect(() => {
		if (!open) {
			setSuccessMesstage("");
		}
	}, [open]);

	const title = !successMesstage ? isEditMode : <></>;

	return (
		<EditarStageContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="adicionar-stage-modal-container"
				title={title}
				closeButtonCb={!successMesstage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMesstage && editMode && (
					<EditStageForm
						stage={stage}
						setOpen={setOpen}
						isLoading={loading}
						setLoading={setLoading}
						setSuccessMesstage={setSuccessMesstage}
						requireStageList={requireStageList}
						setEditMode={setEditMode}
					/>
				)}

				{successMesstage && (
					<div className="edit-stage-success-step">
						<h2>{successMesstage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</EditarStageContainer>
	);
}

export default EditStage;
