import styled from "styled-components";

export const TabMenuContainer = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0 4%;
    margin: 0;
    margin-bottom: 10px;
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

    .menu-disabled {
      color: ${({ theme }) => theme.colors.lightGray};
      opacity: 0.9;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
      }
    }
    div {
      padding: 15px 24px;
      text-decoration: none;
      &:hover {
        background-color: ${({ theme }) => theme.colors.lightGray};
      }
      svg {
        background: none;
        margin-right: 8px;
      }
    }
    .active-nav-tab-item {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;