import styled from "styled-components";

export const AdministracaoPageContainer = styled.div`
    h1 {
        padding: 6px 4%;
    }

    .header-criar-role {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 40px;

        button {
            margin-top: 14px;
            padding: 1.5rem;
        }
    }

`;