import { DimensionDto } from "../../../../../../shared/dto/DimensionDto";

interface DimensionValidation {
  field: string;
  message: string;
}

export function validateDimension(data: DimensionDto, target: 'edit' | 'insert'): DimensionValidation[] {
  const errors: DimensionValidation[] = [] as DimensionValidation[];

  if (target === 'edit' && !data.dimensionId) {
    errors.push({ field: "dimensionId", message: "Erro ao recuperar identificador da dimensão" });
    return errors;
  }

  if (!data.title) {
    errors.push({ field: "title", message: "O campo título é obrigatório" });
  }

  if (!data.description) {
    errors.push({ field: "description", message: "O campo descrição é obrigatório" });
  }

  if (!data.weight) {
    errors.push({ field: "weight", message: "O campo peso é obrigatório" });
  }

  return errors;
}