import { IPageData } from '../dto/PageData';
import { IdeiaDto } from '../dto/IdeiaDto';
import Envoy from '../util/Envoy';
import { UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { MetaSimplificadoDto } from '../dto/MetaSimplificadoDto';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
}

export interface CreateIdeiaDto {
  idea: IdeiaDto;
  coAuthorsId: string[];
}

class IdeaProvider extends Envoy {

  public async getMyIdeas(
    {
      page,
      itemsPerPage,
  }
      : IPaginationDTO): Promise<IPageData<Array<IdeiaDto>>> {
    try {
      const paginationString = this.buildQueryString({page, itemsPerPage});

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token não encontrado.');
      }

      this.conn.defaults.headers.Authorization = `Bearer ${token}`;

      const response = await this.conn.get(`/idea/my${paginationString}`);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter minhas idéias:', error);
      throw error;
    }
  }

  public async createOrUpdate(payload: CreateIdeiaDto): Promise<IdeiaDto> {
    try {
      const response = await this.conn.post('/idea', payload);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao criar/alterar idéia:', error);
      throw error;
    }
  }


  public async delete(ideiaId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/idea/${ideiaId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar fase:', error);
      throw error;
    }
  }

  public async uploadImage(ideiaId: string, image: UploadFile): Promise<void> {
    try {
      const formData = await this.convertUploadFileToFormData(image);

      const response = await this.conn.post(`/idea/${ideiaId}/attach`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao fazer upload de imagem:', error);
      throw error;
    }
  }

  public async replaceOrUploadImage(ideiaId: string, imageId: string, image: UploadFile): Promise<void> {
    try {
      const formData = this.convertUploadFileToFormData(image);

      const response = await this.conn.post(`/idea/${ideiaId}/attach/${imageId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao fazer upload de imagem:', error);
      throw error;
    }
  }

  public async getImages(ideiaId: string,): Promise<MetaSimplificadoDto[]> {
    try {
      const response = await this.conn.get(`/idea/${ideiaId}/meta/images`);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao buscar imagens da idéia:', error);
      throw error;
    }
  }

  private async convertUploadFileToFormData(uploadFile: UploadFile): Promise<FormData> {
    const formData = new FormData();
  
    // Verifica se o arquivo existe e é do tipo RcFile
    if (uploadFile.originFileObj) {
      const file = uploadFile.originFileObj as RcFile;
      const blob = new Blob([file], { type: file.type });
      formData.append('file', blob, file.name);
    } else if (uploadFile.url) {
      const response = await fetch(uploadFile.url);
      const blob = await response.blob();
      formData.append('file', blob, uploadFile.name);
    } else {
      console.error('O arquivo não é válido ou não foi encontrado.');
    }
  
    return formData;
  }

  public async deleteImage(imageId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/idea/image/${imageId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar imagem:', error);
      throw error;
    }
  }

}

export default IdeaProvider;