import styled from "styled-components";

export const EditDimensionFormContainer = styled.form`

section {
  margin: 15px 0;
  #upload-zone {
    height: 67px;
    font-size: 0.833em;
    p {
      margin-bottom: 5px;
    }
  }
}
  .dimension-upload-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .dimension-delete-cover {
    background: none;
    width: 130px;
    height: 39px;
    padding: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    border: 0;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.softGray};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.darkGray};
      cursor: not-allowed;

      svg {
        fill: ${({ theme }) => theme.colors.darkGray};
      }
    }

    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      margin-right: 8px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
    gap: 15px;
  }
`;

export const DimensionCoverImage = styled.div<{ coverImage?: string }>`
  width: 100%;
  height: 348.75px;
  background-color: ${({ theme }) => theme.colors.softGray};
  background-image: url(${({ coverImage }) => coverImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  span {
    background: none;
    font-size: 0.8em;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const ShowDimensionCoverImage = styled.div<{ coverImage?: string }>`
  width: 100%;
  height: 300px;
  background-image: url(${({ coverImage }) => coverImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  
  h2 {
    background: 0;
    color: ${({ theme }) => theme.colors.background};
    font-size: 2em;
    margin: 0 0 30px 30px;
  }
  span {
    background: none;
    font-size: 0.8em;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const EditDivider = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const ShowDimensionContainer = styled.div`
  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .double-button {
      width: 300px;
    }
    
    #voltar-button {
      border: 0;
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1em;
      cursor: pointer;
      font-weight: 500;
    }

    div {
      display: flex;
      gap: 15px;
    }
  }
`;

export const ShowDimensionDescription= styled.div`
    word-wrap: break-word;
    margin: 15px 0;

    p {
        font-size: 0.833em;
        color: ${({theme}) => theme.colors.darkGray};
    }

    background: ${({theme}) => theme.colors.softGray};
    padding: 15px;
    border-radius: 6px;

`;

export const ShowDimensionCriteria = styled.div`
  margin: 15px 0;
  p {
    font-size: 0.833em;
    color: ${({ theme }) => theme.colors.darkGray};
  }
  background: ${({ theme }) => theme.colors.softGray};
  padding: 15px;
  border-radius: 6px;

`;

export const EditDimensionCriteria = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;