import { TableColumnsType } from "antd";
import { RoleItem } from "../../../../../shared/dto/RoleItem";

const columns: TableColumnsType<RoleItem> = [
  {
    title: 'Perfil',
    dataIndex: 'label',
    sorter: (a, b) => a.label.localeCompare(b.label),
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    width: 250,
  },
]

export default columns;