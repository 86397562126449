import React from "react";
import {
    EditDimensionCriteria,
    EditDimensionFormContainer,
    EditDivider,
} from "./styles";
import {toast} from "react-toastify";
import DimensionInput from "../../DimensionInput";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import DimensionProvider from "../../../../../../../shared/provider/DimensionProvider";
import {DimensionDto} from "../../../../../../../shared/dto/DimensionDto";
import {DimensionCategoryDto} from "../../../../../../../shared/dto/DimensionCategoryDto";
import {Button, Flex, Input, Space, Tooltip} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import generateUUID from "../../../../../../../shared/constants/uuid";
import {useIdeia} from "../../../../../../../shared/context/ideia/provider";
import TextArea from "antd/es/input/TextArea";

interface EditDimensionCategoryItem extends DimensionCategoryDto {
    key: string;
}

function EditDimensionForm({
                               setOpen,
                               isLoading = false,
                               setLoading,
                               setSuccessMessage,
                               requireDimensionList,
                               dimension,
                               setEditMode,
                           }: {
    readonly setOpen: React.Dispatch<boolean>;
    readonly isLoading?: boolean;
    readonly setLoading: React.Dispatch<boolean>;
    readonly setSuccessMessage: React.Dispatch<string>;
    readonly requireDimensionList: () => Promise<void>;
    readonly dimension: DimensionDto;
    readonly setEditMode: React.Dispatch<boolean>;
}) {
    const [dimensionName, setDimensionName] = React.useState<string>(
        dimension.title ?? ""
    );
    const [dimensionDescription, setDimensionDescription] = React.useState<string>(
        dimension.description ?? ""
    );

    const [dimensionWeight, setDimensionWeight] = React.useState<string>(
        dimension.weight.toString() ?? ""
    );

    const {messageApi} = useIdeia();

    const [dimensionCategories, setDimensionCategories] = React.useState<EditDimensionCategoryItem[]>(() => {
        if (dimension.categories && dimension.categories.length > 0) {
            return dimension.categories.map((category) => ({
                ...category,
                key: generateUUID(),
            }));
        } else {
            return [] as EditDimensionCategoryItem[];
        }
    });

    const [markedForDeletion, setMarkedForDeletion] = React.useState<string[]>([]);

    const dimensionProvider = new DimensionProvider();

    function addCategory(category: EditDimensionCategoryItem) {
        if (dimensionCategories.length >= 150) {
            toast.error("O número máximo de critérios é 150");
            return;
        }
        setDimensionCategories([...dimensionCategories, category]);
    }

    function removeCategory(category: EditDimensionCategoryItem) {
        if (category.dimensionCategoryId) {
            setMarkedForDeletion([...markedForDeletion, category.dimensionCategoryId]);
        }
        setDimensionCategories(dimensionCategories.filter((c) => c.key !== category.key));
    }

    function updateCategory(category: EditDimensionCategoryItem) {
        const index = dimensionCategories.findIndex((c) => c.key === category.key);
        const newCategories = [...dimensionCategories];
        newCategories[index] = category;
        setDimensionCategories(newCategories);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        updateDimension();
    }

    function removeEmptyCategories() {
        const filtered = dimensionCategories.filter((category) => category.title !== "");
        setDimensionCategories(filtered);
        return filtered;
    }

    async function deleteCriteriaRequest() {
        try {
            const deleteRequests = markedForDeletion.map((criteriaId) =>
                dimensionProvider.deleteCriteria(criteriaId)
            );
            await Promise.all(deleteRequests);
        } catch (error) {
            toast.error("Erro ao deletar critérios");
        }
    }

    async function updateDimension() {
        if (!dimensionName || !dimensionDescription || !dimensionWeight) {
            toast.error("Preencha todos os campos obrigatórios");
            return;
        }

        setLoading(true);

        try {

            if (markedForDeletion.length > 0) {
                await deleteCriteriaRequest();
            }

            const filtered = removeEmptyCategories();

            const response = await dimensionProvider.createOrUpdate({
                dimensionId: dimension.dimensionId,
                title: dimensionName,
                description: dimensionDescription,
                weight: parseInt(dimensionWeight),
                categories: filtered.map((category) => ({
                    dimensionCategoryId: category.dimensionCategoryId,
                    title: category.title,
                    value: category.value,
                })),
            })

            if (!response) throw new Error("Erro ao alterar dimensão");
            setSuccessMessage("Alterações salvas com sucesso!");
            setTimeout(() => requireDimensionList(), 550);
        } catch (error) {
            toast.error("Erro ao alterar dimensão");
        } finally {
            setLoading(false);
        }
    }

    const handleWeightChange = (value: string) => {
        const numericValue = Number(value);
        if (numericValue > 50) {
            setDimensionWeight("50");
        } else if (numericValue < 0) {
            setDimensionWeight("0");
        } else {
            setDimensionWeight(value);
        }
    };

    const handleCategoryValueChange = (value: string, category: EditDimensionCategoryItem) => {
        const numericValue = Number(value);
        if (numericValue > 50) {
            updateCategory({...category, value: 50});
        } else if (numericValue < 0) {
            updateCategory({...category, value: 0});
        } else {
            updateCategory({...category, value: numericValue});
        }
    };

    return (
        <EditDimensionFormContainer onSubmit={handleSubmit}>
            <Flex gap={6} style={{marginBottom: 25, flexDirection: "column" }}>
					<label className={"input-label"} htmlFor="title">
                    Título da dimensão*:
					</label>
					<TextArea
							id="nome"
							showCount
							placeholder="Informe o título da dimensão"
							maxLength={100}
							value={dimensionName}
							onChange={(e) => setDimensionName(e.target.value)}
							autoSize={{ minRows: 1, maxRows: 3 }}
                            disabled={isLoading}
					/>
			</Flex>

            <EditDivider/>

            <DimensionInput
                label="Adicione uma descrição"
                value={dimensionDescription}
                setValue={setDimensionDescription}
                placeholder="Descreva sobre a dimensão"
                isLoading={isLoading}
                width="100%"
                height="130px"
                limitCharacters={400}
                hasCharacters={true}
                type="textarea"
            />

            <DimensionInput
                label="Selecione o peso"
                value={dimensionWeight}
                setValue={handleWeightChange}
                placeholder="0"
                isLoading={isLoading}
                width="12%"
                type="number"
            />

            <span style={{display: "inline-flex", gap: 12, alignContent: "center"}}>
    Critérios
    <Tooltip zIndex={999999} title="Adicionar critério">
     <Button
         type="primary"
         size="small"
         shape="circle"
         icon={<PlusOutlined/>}
         onClick={() => addCategory({title: "", value: 0, key: generateUUID()})}
     />
    </Tooltip>
   </span>
            <EditDimensionCriteria>
                {dimensionCategories.map((category) => (
                    <Flex gap={12} style={{
                        marginTop: 5,
                        alignContent: "center"
                    }}>
                        <Space.Compact>
                            <Input
                                key={`${category.title}#${category.dimensionCategoryId}#title`}
                                style={{width: '80%'}}
                                placeholder="Descreva sobre o critério"
                                defaultValue={category.title}
                                maxLength={150}
                                onBlur={(e) => updateCategory({...category, title: e.target.value})}
                            />

                            <Input
                                key={`${category.title}#${category.dimensionCategoryId}#value`}
                                style={{width: '30%'}}
                                type="number"
                                placeholder="Valor"
                                max={50}
                                min={0}
                                defaultValue={category.value}
                                onBlur={(e) => handleCategoryValueChange(e.target.value, category)}
                                onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Space.Compact>
                        <Flex gap={10}
                              style={{flexDirection: "column", alignContent: "center", justifyContent: "center"}}>
                            <Tooltip zIndex={999999} title="Remover critério">
                                <Button
                                    danger
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    onClick={() => removeCategory(category)}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                ))}
            </EditDimensionCriteria>


            <footer>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    withOutBackground={true}
                    params={{
                        onClick: () => setEditMode(false),
                    }}
                >
                    Voltar
                </DefaultButton>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    params={{
                        type: "submit",
                    }}
                >
                    Salvar
                </DefaultButton>
            </footer>
        </EditDimensionFormContainer>
    );
}

export default EditDimensionForm;