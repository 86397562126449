function getLetterColor(letter: string): string {
	const colorMap: { [key: string]: string } = {
		a: "orange",
		b: "blue",
		c: "red",
		d: "black",
		e: "orange",
		f: "purple",
		g: "pink",
		h: "blue",
		i: "green",
		j: "cyan",
		k: "orange",
		l: "grey",
		m: "red",
		n: "blue",
		o: "pink",
		p: "black",
		q: "orange",
		r: "purple",
		s: "red",
		t: "black",
		u: "blue",
		v: "green",
		w: "orange",
		x: "cyan",
		y: "purple",
		z: "black",
	};

	return colorMap[letter.toLowerCase()] || "black";
}

export default getLetterColor;
