import styled from "styled-components";

export const GestaoUsuarioContainer = styled.div`
    h1 {
        padding: 0 4%;
    }

    .header-criar-usuario {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 40px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 14px;
            padding: 1.5rem;
        }
    }
`;