import React from "react";
import {
	StageInputContainer,
	StageInputElement,
	StageInputTextArea,
} from "./styles";

interface StageInputProps {
	label?: string;
	value: string;
	setValue: React.Dispatch<string>;
	placeholder?: string;
	icon?: JSX.Element;
	message?: string;
	type?: string;
	width?: string;
	height?: string;
	isLoading?: boolean;
	limitCharacters?: number;
}

function StageInput(props: StageInputProps): JSX.Element {
	const [characters, setCharacters] = React.useState(
		props.limitCharacters ?? 0
	);

	React.useEffect(() => {
		if (props.limitCharacters) {
			setCharacters(props.limitCharacters - props.value.length);
		}
	}, [props.value]);

	function handleInput(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) {
		if (props.limitCharacters) {
			if (e.target.value.length <= props.limitCharacters) {
				props.setValue(e.target.value);
			}
		} else {
			props.setValue(e.target.value);
		}
	}

	return (
		<StageInputContainer
			width={props.width}
			height={props.height}
		>
			{props.label && <span className="input-label">{props.label}</span>}

			<div
				className={`stage-input-wrapper ${props.type && props.type === "textarea" ? "custom-text-area" : ""} ${props.isLoading ? "skeleton-loading" : ""}`}
			>
				{props.icon && props.icon}

				{props.type && props.type === "textarea" ? (
					<StageInputTextArea
						placeholder={props.placeholder}
						value={props.value}
						onChange={handleInput}
					/>
				) : (
					<StageInputElement
						type={props.type ?? "text"}
						placeholder={props.placeholder}
						value={props.value}
						onChange={handleInput}
					/>
				)}
				{props.limitCharacters && (
					<span className="character-counter">
						{characters} caracteres restantes
					</span>
				)}
			</div>
			{props.message && <span className="input-message">{props.message}</span>}
		</StageInputContainer>
	);
}

export default StageInput;
