import styled from "styled-components";

export const TenantModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  h1 {
    font-size: 1.5em;
  }
  div {
    width: 100%;
    .autocomplete-form {
      width: 100%;
      .autocomplete-selects {
        max-width: unset;
        ul {
          max-width: unset;
        }
      }
    }
  }
  footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
`;