import styled from "styled-components";

export const EditModalContainer = styled.div``;

export const EditModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-right: 5px;
  max-height: 600px;
  overflow-y: auto;

  label {
    font-size: 14.4px;
    margin-bottom: 3px;
  }
`;