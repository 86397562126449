import axios, { AxiosInstance } from 'axios';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
  orderBy?: string
  direction?: 'asc' | 'desc'
}

axios.defaults.withCredentials = true;

class Envoy {
  protected url: string;
  protected conn: AxiosInstance;
  
  constructor() {
    this.url = process.env.REACT_APP_API_URL || '';

    this.conn = axios.create({
      baseURL: this.url,
      timeout: 60000,
      headers: {
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
      }
    });

    const token = localStorage.getItem('token');
    if (token) {
      this.conn.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }

  protected buildQueryString({page, itemsPerPage, orderBy, direction}: IPaginationDTO): string {
    const queryParams = [];
    if (page) {
      queryParams.push(`p=${page}`);
    }
    if (itemsPerPage) {
      queryParams.push(`i=${itemsPerPage}`);
    }
    if (orderBy) {
      queryParams.push(`ord=${orderBy}`);
    }
    if (direction) {
      queryParams.push(`dir=${direction}`);
    }
    return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  }

}

export default Envoy;