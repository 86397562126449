import React from "react";
import { UploadAreaContainer } from "./styles";
import { FaUpload } from "react-icons/fa";

export interface FileObject {
	name: string;
	size: number;
	type: string;
	data: File | null;
}

interface UploadAreaProps {
	text: string;
	icon?: React.ReactNode;
	file: FileObject | null;
	setFile: React.Dispatch<FileObject | null>;
}

function UploadArea({
											text,
											icon,
											file,
											setFile,
										}: UploadAreaProps): JSX.Element {
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files && e.target.files[0];
		if (selectedFile) {
			const fileObject: FileObject = {
				name: selectedFile.name,
				size: selectedFile.size,
				type: selectedFile.type,
				data: selectedFile,
			};
			setFile(fileObject);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const droppedFile = e.dataTransfer.files[0];
		if (droppedFile) {
			const fileObject: FileObject = {
				name: droppedFile.name,
				size: droppedFile.size,
				type: droppedFile.type,
				data: droppedFile,
			};
			setFile(fileObject);
		}
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<UploadAreaContainer
			className="upload-area"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
		>
			{file ? (
				<div>
					<p>
						{file.name} {file.size} bytes
					</p>
				</div>
			) : null}
			<input
				type="file"
				accept="image/*"
				onChange={handleFileChange}
				style={{ display: "none" }}
				id="uploadInput"
			/>
			<label htmlFor="uploadInput">
				{!file ? (
					<>
						{icon ? icon : <FaUpload />} {text}
					</>
				) : null}
			</label>
		</UploadAreaContainer>
	);
}

export default UploadArea;
