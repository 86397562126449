import styled from "styled-components";

export const ExcluirRoleContainer = styled.div`
  .excluir-modal-breadcrumb-message {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 0.6em;

    svg {
      margin-right: 5px;
    }
  }

  h3 {
    margin: 20px 0;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    word-wrap: break-word; /* Permite a quebra de linha */
    word-break: break-word; /* Quebra palavras longas */
    white-space: normal; /* Permite a quebra de linha */
    svg {
      background: none;
      margin-right: 15px;
      font-size: 1.2em;
    }
  }

  p {
    font-size: 0.855em;
  }

  footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-top: 35px;

    #voltar-delete {
      border: none;
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
      font-weight: 600;
      margin-left: 5px;
    }
    
  }
`;