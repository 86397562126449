import React from "react";
import { LoginDetachContainer } from "./styles";
import Modal from "../../../../shared/util/Modal";
import UserInput from "../../../usuario/components/UserInput";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { FaEnvelope, FaLock } from "react-icons/fa";

interface LoginDetachProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	handleLogin: (email: string, password: string) => Promise<boolean>;
	loading?: boolean;
	userNotFound: boolean;
	setUserNotFound: (userNotFound: boolean) => void;
}

function LoginDetach({
	open,
	setOpen,
	handleLogin,
	loading = false,
	userNotFound = false,
	setUserNotFound,
}: LoginDetachProps): JSX.Element {
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	React.useEffect(() => {
			setEmail("");
			setPassword("");
	}, [open]);

	return (
		<Modal
			open={open}
			setOpen={setOpen}
		>
			<LoginDetachContainer
				onSubmit={(e) => {
					e.preventDefault();
					handleLogin(email, password);
				}}
			>
				{!userNotFound && (
					<>
						<h1>Acesse agora!</h1>

						<UserInput
							label="E-mail"
							type="email"
							value={email}
							setValue={setEmail}
							icon={<FaEnvelope />}
							isLoading={loading}
						/>

						<UserInput
							label="Senha"
							type="password"
							value={password}
							setValue={setPassword}
							icon={<FaLock />}
							isLoading={loading}
						/>

						<div className="detach-login-controller">
							<DefaultButton
								params={{
									type: "button",
									onClick: () => setOpen(false),
									disabled: loading,
								}}
								withOutBackground={true}
							>
								Voltar
							</DefaultButton>

							<DefaultButton
								params={{
									type: "submit",
									disabled: loading,
								}}
							>
								Entrar
							</DefaultButton>
						</div>
					</>
				)}

				{userNotFound && (
					<div className="detach-login-usernotfound-step">
						<h2>Usuário não cadastrado</h2>
						<i>
							Procure seu administrador para recuperar ou criar o seu acesso à
							plataforma IdeiaX.
						</i>
						<div>
							<DefaultButton
								width="100px"
								fontSize="1em"
								height="32px"
								params={{
									type: "button",
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</LoginDetachContainer>
		</Modal>
	);
}

export default LoginDetach;
