import React from "react";
import {
	LoaderFunctionArgs,
	useLoaderData,
	useNavigate,
} from "react-router-dom";
import UserProvider from "../../../../shared/provider/UserProvider";
import {
	PerfilButtonsWrapper,
	PerfilCard,
	PerfilContainer,
	PerfilImagem,
} from "./styles";
import { IUsuarioSimplificado } from "../../../../shared/dto/UsuarioSimplificado";
import moment from "moment";
import Switch from "../../../../shared/util/Switch";
import { FaCheck, FaChevronLeft } from "react-icons/fa";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { toast } from "react-toastify";
import ExcluirUsuarioModal from "../../components/ExcluirUsuario";
import getLetterColor from "../../../../shared/util/GetLetterColor";

export async function loader({
	params,
}: LoaderFunctionArgs<{ profileId: string }>) {
	const userProvider = new UserProvider();
	if (!params.profileId) {
		return { user: null };
	}

	try {
		const user = await userProvider.getById(params.profileId);
		return { user };
	} catch (error) {
		return { user: null };
	}
}

function GestaoPerfil() {
	const { user } = useLoaderData() as { user: IUsuarioSimplificado };
	const [active, setActive] = React.useState(user.active || false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [openExcluirUsuario, setOpenExcluirUsuario] = React.useState(false);
	const navigate = useNavigate();

	const userProvider = new UserProvider();

	function handleUserStatusChange() {
		setIsLoading(true);
		userProvider
			.update(user.id, { ativo: !active })
			.then(() => {
				setActive(!active);
			})
			.catch((error) => {
				toast("Erro ao atualizar status do usuário:");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function handleExcluirUsuario() {
		navigate("/adm/gestao-usuario");
	}

	return (
		<PerfilContainer>
			<ExcluirUsuarioModal
				open={openExcluirUsuario}
				setOpen={setOpenExcluirUsuario}
				user={user}
				onFinished={handleExcluirUsuario}
				breadcrumbElement={
					<>
						<FaChevronLeft />
						Voltar para Ver Perfíl
					</>
				}
			/>
			<section>
				<PerfilCard>
					<header>
						<div>
							<PerfilImagem
								className={isLoading ? "skeleton-loading" : ""}
								letterColor={
									user && user.name ? getLetterColor(user.name.charAt(0)) : ""
								}
								url={
									user.profileImage
										? process.env.REACT_APP_API_PUBLIC_URL +
											"/" +
											user.profileImage
										: ""
								}
							>
								{!user.profileImage &&
									user.name &&
									user.name.length > 0 &&
									user.name.charAt(0)}
							</PerfilImagem>
						</div>
						<div id="gestao-perfil-informacoes">
							<h1>{user.name}</h1>
							<span>
								Criado em {moment(user.createdAt).format("DD/MM/YYYY")}
							</span>

							<div>
								<span className="roletag">{user.roleName}</span>
								<div>
									<span className="input-label">Status</span>
									<Switch
										isOn={active}
										handleToggle={handleUserStatusChange}
										isOnIcon={<FaCheck />}
										isLoading={isLoading}
									/>
									{!isLoading && (
										<span className="input-message">
											Perfíl {active ? "Ativo" : "Inativo"}
										</span>
									)}
								</div>
							</div>
						</div>
					</header>

					<div className="div-divider" />
					<footer>
						<span>
							<b>Email:</b> {user.email}
						</span>
						<span>
							<b>Tenant:</b> {user.tenantName}
						</span>
						{user.position && (
							<span>
								<b>Cargo: </b>
								{user.position}
							</span>
						)}
					</footer>
				</PerfilCard>

				<PerfilButtonsWrapper>
					<div style={{ flexGrow: 1 }}>
						<DefaultButton
							width="100px"
							height="35px"
							fontSize="1em"
							params={{
								type: "button",
								onClick: () => navigate("/adm/gestao-usuario"),
								id: "voltar-usuario-button",
							}}
							withOutBackground={true}
						>
							Voltar
						</DefaultButton>

						<DefaultButton
							width="150px"
							height="35px"
							fontSize="1em"
							params={{
								type: "button",
								disabled: isLoading,
								id: "editar-usuario-button",
								onClick: () =>
									navigate(`/adm/gestao-usuario/perfil/${user.id}/editar`),
							}}
						>
							Editar usuário
						</DefaultButton>
					</div>
					<div>
						<button
							type="button"
							className="red-button"
							disabled={isLoading}
							onClick={() => setOpenExcluirUsuario(true)}
						>
							Excluir usuário
						</button>
					</div>
				</PerfilButtonsWrapper>
			</section>

			<section id="perfil-ultimas-ideias">
				<h2>Ideias</h2>
			</section>
		</PerfilContainer>
	);
}

export default GestaoPerfil;
