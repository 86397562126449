import React from "react";
import { Input } from "antd";
import {
  DimensionInputContainer,
  DimensionInputElement,
  DimensionInputTextArea,
} from "./styles";

interface DimensionInputProps {
  label?: string;
  value: string;
  setValue: React.Dispatch<string>;
  placeholder?: string;
  icon?: JSX.Element;
  message?: string;
  type?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  limitCharacters?: number;
  hasCharacters?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
}

function DimensionInput(props: DimensionInputProps): JSX.Element {
  const [characters, setCharacters] = React.useState(props.value.length);

  React.useEffect(() => {
    setCharacters(props.value.length);
  }, [props.value]);

  function handleInput(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (props.limitCharacters) {
      if (e.target.value.length <= props.limitCharacters) {
        props.setValue(e.target.value);
      }
    } else {
      props.setValue(e.target.value);
    }
  }

  return (
    <DimensionInputContainer width={props.width} height={props.height}>
      {props.label && <span className="input-label">{props.label}</span>}

      <div
        className={`dimension-input-wrapper ${
          props.type && props.type === "textarea" ? "custom-text-area" : ""
        } ${props.isLoading ? "skeleton-loading" : ""}`}
      >
        {props.icon && props.icon}

        {props.type && props.type === "textarea" ? (
          <DimensionInputTextArea
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleInput}
          />
        ) : (
          <DimensionInputElement
            type={props.type ?? "text"}
            placeholder={props.placeholder}
            value={props.value}
            onChange={handleInput}
            onBlur={props.onBlur}
            style={props.style}
          />
        )}
        {props.hasCharacters && props.limitCharacters && (
          <span className="character-counter">
            {characters} / {props.limitCharacters}
          </span>
        )}
      </div>
      {props.message && <span className="input-message">{props.message}</span>}
    </DimensionInputContainer>
  );
}

export default DimensionInput;