import styled from "styled-components";

export const EditarPerfilContainer = styled.div`
  h1 {
    padding: 0 4%;
  }
`;

export const UploadImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 4%;
  margin-top: 40px;
`;

export const AtualizarGestaoPerfil = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 4%;

  footer {
    display: flex;
    gap: 45px;
  }

  .adicionar-usuario-wrapper {
    display: flex;
    margin-top: 2%;
    gap: 15px;
    section {
      max-width: 500px;
      width: 100%;
    }
    @media(max-width: 580px) {
      flex-wrap: wrap;
    }
  }
`;

export const PerfilImagem = styled.div<{url: string, letterColor: string}>`
  background: ${({ url, letterColor }) => (url ? `url(${url})` : letterColor)};
  background-size: cover;
  background-position: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.background};
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0
`;