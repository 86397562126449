import React from "react";
import { EditarRoleContainer } from "./styles";
import Modal from "../../../../shared/util/Modal";
import EditRoleForm from "./components/addRoleForm";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { OptionValue } from "../../../../shared/dto/OptionValue";

function EditRole({
	open,
	setOpen,
	requireRoleList,
	role,
}: {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	requireRoleList(): Promise<void>;
	role: OptionValue;
}) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	return (
		<EditarRoleContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="editar-role-modal-container"
				title={!successMessage ? <h2>Editar perfíl - {role.label}</h2> : <></>}
				closeButtonCb={!successMessage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMessage && (
					<>
						<EditRoleForm
							setOpen={setOpen}
							isLoading={loading}
							setLoading={setLoading}
							setSuccessMessage={setSuccessMessage}
							requireRoleList={requireRoleList}
							role={role}
						/>
					</>
				)}

				{successMessage && (
					<div className="edit-role-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</EditarRoleContainer>
	);
}

export default EditRole;
