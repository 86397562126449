import React, { useEffect } from "react";
import {
	EditUserFormContainer,
	EditarFormButtonsWrapper,
	EditarFormCheckboxContainer,
} from "./styles";
import { OptionValue } from "../../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../../shared/provider/RoleProvider";
import DefaultButton from "../../../../../shared/util/DefaultButton";
import { toast } from "react-toastify";
import { CreateRoleDto } from "../../../../../shared/dto/CreateRoleDto";
import { PERMISSIONS } from "../../../../../shared/guard/Permissions";
import Checkbox from "../../../../../shared/util/Checkbox";
import { UpdateRoleDto } from "../../../../../shared/dto/UpdateRoleDto";

interface CheckboxItem {
	label: string;
	checked: boolean;
}

function EditRoleForm({
												setOpen,
												isLoading = false,
												setLoading,
												setSuccessMessage,
												requireRoleList,
												role,
											}: {
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
	setLoading: React.Dispatch<boolean>;
	setSuccessMessage: React.Dispatch<string>;
	requireRoleList: () => Promise<void>;
	role: OptionValue;
}) {
	const [nome, setNome] = React.useState("");

	const roleProvider = new RoleProvider();

	const [checkboxes, setCheckboxes] = React.useState<{
		[key: string]: CheckboxItem;
	}>({});

	// Quando os dados da API são recebidos
	React.useEffect(() => {
		onInit();
	}, []);

	async function onInit() {
		await fetchApiData();
		await requireRole();
	}

	const fetchApiData = async () => {
		const data = await roleProvider.getPermissionOptions(); // Substitua isso pela sua chamada de API
		const initialCheckboxesState: { [key: string]: CheckboxItem } = data.reduce(
			(acc: any, item: OptionValue) => {
				acc[item.value] = { label: item.label, checked: false }; // Inicialmente, todos os checkboxes estão desmarcados
				return acc;
			},
			{}
		);
		setCheckboxes(initialCheckboxesState);
	};

	async function requireRole() {
		try {
			const response = await roleProvider.get(role.value);

			if (response) {
				setCheckboxes((prev) => {
					const newState = { ...prev };
					Object.keys(newState).forEach((key) => {
						newState[key].checked = response.permissions.includes(
							key as PERMISSIONS
						);
					});
					return newState;
				});
			}
		} catch (error) {}
	}

	function clearFields() {
		setNome("");
		setCheckboxes((prev) => {
			const newState = { ...prev };
			Object.keys(newState).forEach((key) => {
				newState[key].checked = false;
			});
			return newState;
		});
	}

	function checkAllFields() {
		setCheckboxes((prev) => {
			const newState = { ...prev };
			Object.keys(newState).forEach((key) => {
				newState[key].checked = true;
			});
			return newState;
		});
	}

	// Para atualizar o estado quando um checkbox é marcado ou desmarcado
	const handleCheckboxChange = (value: string) => {
		setCheckboxes((prev) => ({
			...prev,
			[value]: {
				...prev[value],
				checked: !prev[value].checked,
			},
		}));
	};

	async function updateRole(data: UpdateRoleDto) {
		setLoading(true);
		const response = await roleProvider.update(role.value, data);
		if (response) {
			await requireRoleList();
			setSuccessMessage(`O perfíl "${response}" foi editado com sucesso!`);
			clearFields();
		} else {
			toast.error("Erro ao cadastrar usuário");
		}
		setLoading(false);
	}

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const updatedRole: UpdateRoleDto = {
			permissions: Object.keys(checkboxes).filter(
				(key) => checkboxes[key] && checkboxes[key].checked
			) as PERMISSIONS[],
		};

		updateRole(updatedRole);
	}
	return (
		<EditUserFormContainer onSubmit={handleSubmit}>
			<EditarFormCheckboxContainer>
				<h3>Permissões</h3>
				<p>Funcionalidades que este perfíl terá acesso</p>
				<section>
					{Object.keys(checkboxes)
						.sort((a, b) =>
							checkboxes[a].label.localeCompare(checkboxes[b].label)
						)
						.map((key) => (
							<ul key={key}>
								<Checkbox
									checked={checkboxes[key].checked}
									onChange={handleCheckboxChange}
									label={checkboxes[key].label}
									checkboxKey={key}
								/>
							</ul>
						))}
				</section>
			</EditarFormCheckboxContainer>

			<EditarFormButtonsWrapper>
				<div>
					<DefaultButton
						width="220px"
						height="30px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => clearFields(),
						}}
						padding="1.5rem"
						withOutBackground={true}
					>
						Limpar todos os campos
					</DefaultButton>

					<DefaultButton
						width="220px"
						height="30px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => checkAllFields(),
						}}
						padding="1.5rem"
						withOutBackground={true}
					>
						Marcar todos os campos
					</DefaultButton>

					<DefaultButton
						width="150px"
						height="30px"
						fontSize="1em"
						params={{
							type: "submit",
							disabled: isLoading,
						}}
						padding="1.5rem"
					>
						Salvar alterações
					</DefaultButton>
				</div>
			</EditarFormButtonsWrapper>
		</EditUserFormContainer>
	);
}

export default EditRoleForm;
