import styled from "styled-components";

export const IdeiaContainer = styled.div`
    background: none;

    .icon-container {
        display: flex;
        align-items: center;
        padding: 1.5rem;
    }

    .icon {
        transition: color 0.3s;
        color: #1351B4;
    }

    .icon-container:hover .icon {
        color: #1677FF;
    }
`;