import { FaTrash } from "react-icons/fa";
import { DimensionDto } from "../../../../../shared/dto/DimensionDto";
import { DimensionItem } from "../../../../../shared/dto/DimensionItem";
import { Tooltip } from "antd";

interface MapProps {
  dto: DimensionDto[];
  deleteFn: (dimension: DimensionDto) => void;
  viewFn: (dimension: DimensionDto) => void;
}

function mapDimensionDtoListToItems({ dto, deleteFn, viewFn } : MapProps): DimensionItem[] {
  return dto.map((item) => ({
    ...item,
    action: <>
    <Tooltip title="Deletar dimensão">
      <span
        className="dimension-list-delete-button"
        onClick={() => deleteFn(item)}
      >
        <FaTrash />
      </span>
    </Tooltip>
    <span
      className="dimension-list-editar-button"
      onClick={() => viewFn(item)}
    >
      Visualizar
    </span>
    </>
  }));
}

export default mapDimensionDtoListToItems;