import { TableColumnsType } from "antd";
import { CategoryItem } from "../../../../../../../shared/dto/CategoryItem";

const columns: TableColumnsType<CategoryItem> = [
  {
    dataIndex: 'coverImage',
    render: (coverImage) => <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/${coverImage}?t${new Date().getTime()}`} alt="Cover" style={{ width: 158, height: 67 }} />,
    width: 158,
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    width: 250,
  },
]

export default columns;