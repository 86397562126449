import styled from "styled-components";

export interface ButtonProps {
    withOutBackground?: boolean;
    marginTop?: string;
    width?: string;
    height?: string;
    fontSize?: string;
    padding?: string;
}

export const DefaultButtonContainer = styled.button<ButtonProps>`
    background-color: ${(props) => (props.withOutBackground ? "transparent" : props.theme.colors.primary)};
    color: ${(props) => (props.withOutBackground ? props.theme.colors.primary : props.theme.colors.background)};
    margin-top: ${(props) => props.marginTop || "0"};
    padding:  ${(props) => props.padding || "0"};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: ${(props) => props.width || "450px"};
    width: 100%;
    height: ${(props) => props.height || "43px"};
    border: ${(props) => props.withOutBackground ? `1px solid ${props.theme.colors.primary}` : 'none'};
    border-radius: 30px;
    font-size: ${(props) => props.fontSize || "1.3em"};
    font-weight: 500;

    ${props => props.withOutBackground && `
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.background};

      svg {
        path {
          color: ${props.theme.colors.background};
        }
      
      }
    }
  `}
    &:hover {
        cursor: pointer;
    }

    svg {
        background: none;
        font-size: 1em;
        margin-right: 5px;

        path {
            color: ${(props) => (props.withOutBackground ? props.theme.colors.primary : props.theme.colors.background)};
        }
    }

    &:disabled {
        background-color: #d3d3d3;
        color: #a9a9a9;
        border: 0;
        cursor: not-allowed;
    }
`;