import styled from "styled-components";

export const FooterContainer = styled.footer`
  padding: 4% 8%;
  background: url("/assets/images/grafismo_rodape1.png") no-repeat center right;
  background-color: #d6d6d6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  * {
    background: none;
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  :nth-child(2) {
    
  }
  
  head {
    width: 100%;
    display: flex;
    background: transparent;
    gap: 100px;

    div {
      background: transparent;

      a {
        text-decoration: none;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background: none;
        h3 {
          font-size: 1.2rem;
          color: ${({ theme }) => theme.colors.background};
          font-weight: bold;
          margin-top: 10px;
        }
        img {
          width: 110px;
        }
      }
    }

    nav {
      max-width: 400px;
      width: 100%;
      ul {
        width: 100%;
        list-style: none;
        li {
          font-size: 0.966rem;
          padding: 10px;
          transition: all 0.3s ease-in-out;
          width: 100%;
          cursor: pointer;
          &:hover {
            background-color: ${({ theme }) => theme.colors.lightGray};
          }
        }
      }
    }
  }
  
  section {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    background: none;
    justify-content: flex-end;
    a {
      background: transparent;
    }
    span {
      font-size: 0.833rem;
    }
  }
`;

export const FooterAcessoInformacaoImage = styled.div<{image: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  background: url(${props => props.image}) no-repeat center center;
  background-size: contain;
  background-color: transparent;
  height: 55px;
`;