import React, { useEffect, useRef } from "react";
import { ModalBackdrop, ModalContainer } from "./styles";
import { IoMdCloseCircle } from "react-icons/io";

interface ModalProps {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	children: React.ReactNode;
	backdropClassName?: string;
	containerClassName?: string;
	disableClickOutside?: boolean;
	borderRadius?: string;
	closeButtonCb?: () => void;
	title?: JSX.Element;
}

function Modal(props: ModalProps) {
	const { open, setOpen, children } = props;
	const modalRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			!props.disableClickOutside &&
			modalRef.current &&
			!modalRef.current.contains(event.target as Node)
		) {
			setOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	if (!open) return null;

	return (
		<ModalBackdrop className={props.backdropClassName}>
			<ModalContainer
				ref={modalRef}
				className={props.containerClassName}
				style={{ borderRadius: props.borderRadius }}
			>
				{props.title && (
					<header>
						{props.title}
						{!!props.closeButtonCb && (
							<button
								type="button"
								onClick={props.closeButtonCb}
								title="Fechar"
							>
								<IoMdCloseCircle />
							</button>
						)}
					</header>
				)}
				{children}
			</ModalContainer>
		</ModalBackdrop>
	);
}

export default Modal;
