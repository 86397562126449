import React from "react";
import * as css from "./styles";
import LoginBox from "../components/LoginBox";
import LoginSlide from "../components/LoginSlide";

function LoginPage() {
	return (
		<css.LoginContainer>
			<LoginBox />

			<LoginSlide />
		</css.LoginContainer>
	);
}

export default LoginPage;
