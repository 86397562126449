class PermissionValidator {
  private role: string;
  private permissions: string[];

  constructor() {
      this.role = localStorage.getItem('role') || '';
      this.permissions = localStorage.getItem('permissions')?.split(',') || [];
  }

  hasAccess(role: string | string []): boolean {
    if (Array.isArray(role)) {
        return role.some(r => this.role.includes(r));
    }
      return this.role.includes(role);
  }

  hasPermission(permission: string | string []): boolean {
    if (Array.isArray(permission)) {
        return permission.some(p => this.permissions.includes(p));
    }
      return this.permissions.includes(permission);
  }

}

export default PermissionValidator;