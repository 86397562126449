import React from "react";
import {
	LoginBoxContainer,
	LoginBoxContent,
	LoginBoxFooter,
	LoginBoxHeader,
} from "./styles";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { toast } from "react-toastify";
import Logo from "../../../../shared/util/Logo";
import { useNavigate } from "react-router-dom";
import AuthProvider from "../../../../shared/provider/AuthProvider";
import LoginDetach from "../LoginDetach";
import validator from "validator";

function LoginBox(): JSX.Element {
	const Logotipo = Logo("200px", "100px");
	const navigate = useNavigate();
	const authProvider = new AuthProvider();

	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [userNotFound, setUserNotFound] = React.useState(false);

	React.useEffect(() => {
		if (!open) setUserNotFound(false);
	}, [open]);

	React.useEffect(() => {
		tryRefreshToken();
	}, []);

	React.useEffect(() => {
		if (open) {
			window.localStorage.removeItem("tenantName");
		}
	}, [open]);

	async function tryRefreshToken() {
		try {
			const response = await authProvider.refreshSession();
			if (!response) return false;
			navigate("/");
			return true;
		} catch (error) {
			return false;
		}
	}

	async function handleLogin(
		email: string,
		password: string
	): Promise<boolean> {
		try {
			setLoading(true);
			if (!validator.isEmail(email)) {
				toast("E-mail inválido", { type: "warning" });
				throw new Error("E-mail inválido");
			}

			if (!password) {
				toast("A Senha não foi inserida", { type: "warning" });
				throw new Error("A Senha não foi inserida");
			}

			const response = await authProvider.login({
				email,
				password,
			});

			setLoading(false);
			setOpen(false);

			toast(
				<>
					<b>Sucesso.</b> Usuário validado com sucesso!
				</>,
				{ type: "success", autoClose: 400, onClose: () => navigate("/") }
			);
			return response;
		} catch (error: any) {
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.messages &&
				error.response.data.messages.length
			) {
				setUserNotFound(true);
			}
			setLoading(false);
			return false;
		}
	}

	return (
		<LoginBoxContainer>
			<LoginDetach
				open={open}
				setOpen={setOpen}
				loading={loading}
				handleLogin={handleLogin}
				userNotFound={userNotFound}
				setUserNotFound={setUserNotFound}
			/>
			<LoginBoxHeader>
				<h2>{Logotipo}</h2>
			</LoginBoxHeader>

			<LoginBoxContent>
				<h3>
					Acesse agora mesmo sua nova <br />
					plataforma de gerar ideias
				</h3>

				<p>Para acessar é necessário ter um perfil habilitado</p>

				<DefaultButton
					marginTop="8%"
					params={{
						onClick: () => setOpen(true),
					}}
				>
					Login
				</DefaultButton>
			</LoginBoxContent>

			<LoginBoxFooter>
				<span>
					Acesso o{" "}
					<a
						href="https://dataprev.gov.br"
						target="_blank"
						rel="noreferrer"
					>
						dataprev.gov.br
					</a>
				</span>
				<span>Termos de uso e aviso de privacidade</span>
			</LoginBoxFooter>
		</LoginBoxContainer>
	);
}

export default LoginBox;
