import React, { useEffect, useState } from "react";
import {
	UserLine,
	UserListContainer,
	UserListContent,
	UserListHeader,
	UserListImage,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { IPageData } from "../../../../shared/dto/PageData";
import HeaderTitle from "./config/HeaderTitle";
import { IUsuarioSimplificado } from "../../../../shared/dto/UsuarioSimplificado";
import SearchInput from "../SearchInput";
import FiltroSelect from "../Filtro";
import UserProvider from "../../../../shared/provider/UserProvider";
import EditarUsuario from "../EditarUsuario";
import Pagination from "../../../../shared/util/Pagination";
import getLetterColor from "../../../../shared/util/GetLetterColor";
import AdicionarUsuario from "../AdicionarUsuario";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import sortList from "../../../../shared/util/Order";

function UserList({
	openAddUser,
	setOpenAddUser,
}: {
	openAddUser: boolean;
	setOpenAddUser: React.Dispatch<boolean>;
}): JSX.Element {
	const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">();
	const [sortKey, setSortKey] = React.useState<string>();
	const [editUser, setEditUser] = React.useState(false);
	const [selectedUserForEdit, setSelectedUserForEdit] = React.useState(
		{} as IUsuarioSimplificado
	);

	const [response, setResponse] = useState<
		IPageData<Array<IUsuarioSimplificado>>
	>({} as IPageData<Array<IUsuarioSimplificado>>);
	const [list, setList] = useState<IUsuarioSimplificado[]>([]);

	const userProvider = new UserProvider();
	const navigate = useNavigate();

	function getPreviousSelectedUserForEdit(data: IUsuarioSimplificado[]) {
		if (!data) return;
		const user = data.find((user) => user.id === selectedUserForEdit.id);
		if (user) setSelectedUserForEdit(user);
	}

	async function requireUserList(
		page = 1,
		itemsPerPage = 10,
		orderBy?: string,
		direction?: "asc" | "desc"
	) {
		try {
			const data = await userProvider.getAllUsers({
				page,
				itemsPerPage,
				orderBy,
				direction,
			});
			if (!data) return;

			setResponse(data);
			getPreviousSelectedUserForEdit(data.data);
		} catch (error) {
			toast(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
				{
					type: "error",
					autoClose: 500,
					onClose: () => {
						navigate("/entrar");
					},
				}
			);
		}
	}

	useEffect(() => {
		requireUserList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleEditUserClick(user: IUsuarioSimplificado) {
		setSelectedUserForEdit(user);
		setEditUser(true);
	}

	function handleSortList() {
		if (sortKey) {
			const direction = sortDirection === "asc" ? "desc" : "asc";
			const sorted = sortList(
				response.data,
				sortKey as keyof IUsuarioSimplificado,
				direction
			);
			setList(sorted);
			setResponse({ ...response, data: sorted });
		}
	}

	React.useEffect(() => {
		handleSortList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection, sortKey]);

	return (
		<UserListContainer>
			<AdicionarUsuario
				open={openAddUser}
				setOpen={setOpenAddUser}
				requireUserList={requireUserList}
			/>
			<EditarUsuario
				open={editUser}
				setOpen={setEditUser}
				user={selectedUserForEdit}
				requireUserList={requireUserList}
			/>

			<UserListHeader>
				<SearchInput setListPaginated={setResponse} />
				{response && <span>{response.totalItems} usuários cadastrados</span>}
			</UserListHeader>

			<UserListContent>
				<thead className="listing-header">
					{HeaderTitle.map((header) => (
						<td
							key={header.key}
							data-name={header.dataName}
							className="th-style"
							onClick={() => {
								if (header.key === "actions") return;
								if (sortKey === header.key) {
									setSortDirection(sortDirection === "asc" ? "desc" : "asc");
								} else {
									setSortKey(header.key);
									setSortDirection("asc");
								}
							}}
						>
							{header.name}
							{sortKey === header.key && header.key !== "actions" && (
								<span className="orderButton">
									{header.key === sortKey && sortDirection === "asc" ? (
										<FaSortAlphaUp />
									) : (
										<FaSortAlphaDown />
									)}
								</span>
							)}
						</td>
					))}
				</thead>

				<tbody>
					{response &&
						response.data?.map((user) => (
							<UserLine key={user.id}>
								<td data-name="userName">
									<UserListImage
										letterColor={
											user && user.name
												? getLetterColor(user.name.charAt(0))
												: ""
										}
										image={
											user.profileImage
												? process.env.REACT_APP_API_PUBLIC_URL +
													"/" +
													user.profileImage
												: ""
										}
									>
										{!user.profileImage &&
											user.name &&
											user.name.length > 0 &&
											user.name.charAt(0)}
									</UserListImage>
									<span>{user.name}</span>
								</td>
								<td data-name="userEmail">{user.email}</td>
								<td data-name="userTenant">{user.tenantName}</td>
								<td data-name="userCreatedAt">
									{moment(user.createdAt).format("DD/MM/YYYY")}
								</td>
								<td data-name="userRole">{user.roleName}</td>
								<td
									data-name="userActions"
									className="td-action"
								>
									<span
										className="user-list-editar-button"
										onClick={() => handleEditUserClick(user)}
									>
										editar
									</span>
								</td>
							</UserLine>
						))}
				</tbody>
				<Pagination
					data={response}
					request={requireUserList}
				/>
			</UserListContent>
		</UserListContainer>
	);
}

export default UserList;
