import React from "react";
import { AdministracaoPageContainer } from "./styles";
import DefaultButton from "../../../shared/util/DefaultButton";
import { FaPlus } from "react-icons/fa";
import RoleList from "../components/RoleList";
import AdminTabMenu from "../components/TabMenu";
import CategoryList from "../components/Category";
import DimensionList from "../components/Dimension";
import StageList from "../components/Stage";

function AdministracaoPage(): JSX.Element {
	const [openAddRole, setOpenAddRole] = React.useState(false);
	const [openAddCategory, setOpenAddCategory] = React.useState(false);
	const [openAddDimension, setOpenAddDimension] = React.useState(false);
	const [openAddStage, setOpenAddStage] = React.useState(false);
	const [menuActive, setMenuActive] = React.useState("Permissões");

	const createButtonVisibleWhen = ["Permissões", "Categorias", "Dimensão", "Fases"];

	function handleCreateButton(value: boolean) {
		if (menuActive === "Permissões") setOpenAddRole(value);
		if (menuActive === "Categorias") setOpenAddCategory(value);
		if (menuActive === "Dimensão") setOpenAddDimension(value);
		if (menuActive === "Fases") setOpenAddStage(value);
	}

	function renderCreateButtonName() {
		if (menuActive === "Permissões") return "Nova Permissão";
		if (menuActive === "Categorias") return "Nova Categoria";
		if (menuActive === "Desafios") return "Novo Desafio";
		if (menuActive === "Dimensão") return "Nova Dimensão";
		if (menuActive === "Fases") return "Nova Fase";
	}

	return (
		<AdministracaoPageContainer>
			<div className="header-criar-role">
				{createButtonVisibleWhen.includes(menuActive) ? (
					<DefaultButton
						width="220px"
						height="35px"
						fontSize="1em"
						params={{
							onClick: () => handleCreateButton(true),
						}}
					>
						<FaPlus />
						{renderCreateButtonName()}
					</DefaultButton>
				) : (
					<DefaultButton
						width="220px"
						height="35px"
						fontSize="1em"
						padding="1.5rem"
						params={{
							disabled: true,
						}}
					>
						<FaPlus />
						{renderCreateButtonName()}
					</DefaultButton>
				)}
			</div>

			<h1>Administração</h1>

			<section>
				<AdminTabMenu
					active={menuActive}
					setActive={setMenuActive}
				/>

				{menuActive === "Permissões" && (
					<RoleList
						openAddRole={openAddRole}
						setOpenAddRole={setOpenAddRole}
					/>
				)}

				{menuActive === "Categorias" && (
					<CategoryList
						openAddCategory={openAddCategory}
						setOpenAddCategory={setOpenAddCategory}
					/>
				)}

				{menuActive === "Dimensão" && (
					<DimensionList
						openAddDimension={openAddDimension}
						setOpenAddDimension={setOpenAddDimension}
					/>
				)}

				{menuActive === "Fases" && (
					<StageList
						openAddStage={openAddStage}
						setOpenAddStage={setOpenAddStage}
					/>
				)}
			</section>
		</AdministracaoPageContainer>
	);
}

export default AdministracaoPage;
