import styled from "styled-components";

export const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
  font-size: 1.1em;
  @media(max-width: 650px) {
    height: 75vh;
  }
`;

interface SlideDto {
  isActive: boolean;
  backgroundImage: string;
}
export const Slide = styled.div<SlideDto>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.5s ease;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 2.5em;

  h2, span {
    transition: all 500ms ease-in-out;
  }
  
  @media(max-width: 750px) {
    text-align: center;
  }
  @media(max-width: 650px) {
      font-size: 0.9em;
      padding: 0 1em;
      h2 {
        font-size: 1em;
      }
  }
  h2 {
    margin-bottom: 1em;
  }
`;