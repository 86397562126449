const slideList = [
	{
		path: "/assets/images/slide/01.png",
		title: "Compartilhando ideias",
		caption: (
			<span>
				Uma plataforma aberta de gestão da inovação para estimular a
				participação
				<br />
				das pessoas da organização no processo de criação de novas soluções!
			</span>
		),
	},
	{
		path: "/assets/images/slide/02.png",
		title: "Faça parte da comunidade",
		caption: (
			<span>
				Quer implementar ou melhorar um processo de gestão da
				<br />
				inovação em sua organização? Entre e faça parte da comunidade!
			</span>
		),
	},
	{
		path: "/assets/images/slide/03.png",
		title: "Lance suas ideias para todos",
		caption: (
			<span>
				O ideia X é o canal para divulgar e compartilhar suas ideias, permitindo
				que
				<br />
				seus pares possam contribuir, discutir e colaborar para o crescimento
				dela!
				<br />
			</span>
		),
	},
];

export default slideList;
