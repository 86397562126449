import React from "react";
import {ExcluirUsuarioContainer} from "./styles";
import Modal from "../../../../shared/util/Modal";
import {IUsuarioSimplificado} from "../../../../shared/dto/UsuarioSimplificado";
import DefaultButton from "../../../../shared/util/DefaultButton";
import UserProvider from "../../../../shared/provider/UserProvider";
import {FaUserTimes} from "react-icons/fa";

function ExcluirUsuarioModal({
                                 open,
                                 setOpen,
                                 user,
                                 onFinished,
                             }: {
    open: boolean;
    setOpen: React.Dispatch<boolean>;
    user: IUsuarioSimplificado;
    onFinished?: () => void;
    breadcrumbElement?: JSX.Element;
}) {
    const [successMessage, setSuccessMessage] = React.useState("");
    const userProvider = new UserProvider();
    React.useEffect(() => {
        if (!open) {
            setSuccessMessage("");
        }
    }, [open]);

    async function handleExcluirUsuario() {
        if (user) {
            try {
                await userProvider.delete(user.id);
                setSuccessMessage("Perfíl do usuário excluido com sucesso.");
            } catch (error) {
                console.error("Erro ao excluir usuário:", error);
            }
            return;
        }
    }

    return (
        <ExcluirUsuarioContainer>
            <Modal
                open={open}
                setOpen={setOpen}
            >
                {!successMessage && (
                    <>
                        <h3
                            style={{
                                width: "100%",
                                justifyContent: "center"
                            }}
                        >
                            Apagar Usuário
                        </h3>
                        <p
                            style={{
                                textAlign: "center",
                            }}
                        >
                            O usuário <b>"{user.name}"</b> será apagado. Esta operação não
                            pode ser desfeita. Confirmar a ação?
                        </p>

                        <footer>
                            <DefaultButton
                                width="125px"
                                height="36px"
                                fontSize="1em"
                                params={{
                                    onClick: handleExcluirUsuario,
                                }}
                            >
                                Apagar
                            </DefaultButton>

                            <DefaultButton
                                width="125px"
                                height="36px"
                                fontSize="1em"
                                withOutBackground={true}
                                params={{
                                    onClick: () => setOpen(false),
                                }}
                            >
                                Voltar
                            </DefaultButton>
                        </footer>
                    </>
                )}

                {successMessage && (
                    <div className="edit-user-success-step">
                        <h2
                            style={{
                                textAlign: "center",
                            }}
                        >{successMessage}</h2>
                        <div>
                            <DefaultButton
                                withOutBackground={true}
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                params={{
                                    onClick: () => {
                                        setOpen(false);
                                        onFinished && onFinished();
                                    },
                                }}
                            >
                                Fechar
                            </DefaultButton>
                        </div>
                    </div>
                )}
            </Modal>
        </ExcluirUsuarioContainer>
    );
}

export default ExcluirUsuarioModal;
