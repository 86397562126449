export const TabItems = [
  {
    label: 'Detalhes',
    key: 'details'
  },
  {
    label: 'Comentários',
    key: 'comments'
  },
  {
    label: 'Ideias Relacionadas',
    key: 'relatedIdeas'
  },
  {
    label: 'Desafio',
    key: 'challenge'
  }
]
