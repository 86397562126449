import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import CustomThemeProvider from "./styles/context/customThemeProvider";
import { RouterProvider } from "react-router-dom";
import IdeiaXRouter from "./router";
import NotifyContainer from "./shared/util/NotifyContainer";
import IdeiaContextProvider from "./shared/context/ideia/provider";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<CustomThemeProvider>
		<IdeiaContextProvider>
			<React.StrictMode>
				<NotifyContainer />
				<RouterProvider router={IdeiaXRouter}></RouterProvider>
			</React.StrictMode>
		</IdeiaContextProvider>
	</CustomThemeProvider>
);

reportWebVitals();
