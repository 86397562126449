import React from "react";
import { AdicionarUsuarioContainer } from "./styles";
import AdicionarUsuarioForm from "./components/addUserForm";
import DefaultButton from "../../../../shared/util/DefaultButton";
import { Modal } from "antd";

function AdicionarUsuario({
	open,
	setOpen,
	requireUserList,
}: {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	requireUserList(): Promise<void>;
}) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	return (
		<AdicionarUsuarioContainer>
			<Modal
				open={open}
				title={!successMessage ? <h2>Adicionar usuário</h2> : <></>}
				maskClosable={false}
				onCancel={() => setOpen(false)}
				okButtonProps={{ style: { display: "none"} }}
				cancelButtonProps={{ style: { display: "none"} }}
				width={successMessage ? 450 : 1280}
			>
				{!successMessage && (
					<>
						<AdicionarUsuarioForm
							setOpen={setOpen}
							isLoading={loading}
							setLoading={setLoading}
							setSuccessMessage={setSuccessMessage}
							requireUserList={requireUserList}
						/>
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<h2
							style={{
								textAlign: "center",
							}}
						>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</AdicionarUsuarioContainer>
	);
}

export default AdicionarUsuario;
