import styled from "styled-components";

interface LogoContainerProps {
  path: string;
  width: string;
  height: string;
}

export const LogoContainer = styled.div<LogoContainerProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-image: ${({ path }) => `url(${path})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;