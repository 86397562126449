import { OptionValue } from '../dto/OptionValue';
import Envoy from '../util/Envoy';

class TenantProvider extends Envoy {
  public async getAll(): Promise<Array<OptionValue>> {
    try {
      const response = await this.conn.get('/tenant');
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));

      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter todos os locatários:', error);
      throw error;
    }
  }
  public async getFromLoggedUser(): Promise<Array<OptionValue>> {
    try {
      const response = await this.conn.get('/tenant/user/options');
      
      if (response.data.messages && response.data.messages.length)
        throw new Error(response.data.messages.join('\n'));
      
      return response.data.data;
    } catch (error) {
      console.error('Erro ao obter as opções do usuário:', error);
      throw error;
    }
  }
}

export default TenantProvider;