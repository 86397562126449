import styled from "styled-components";

export const VisualizarUsuarioContainer = styled.div`
  transition: all 0.5 ease-in-out;
  header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 2em;
    }
  }

  .visualizar-usuario-modal-container {
    max-width: 1280px;
    width: 100%;
    height: 720px;
    padding: unset;
    header {
      padding: 2% 4%;
    }
  }
`;

export const VisualizarUsuarioImagem = styled.div<{url: string, letterColor: string}>`
  background: ${({ url, letterColor }) => (url ? `url(${url})` : letterColor)};
  background-size: cover;
  background-position: center;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.background};
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0
`;

export const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 4%;
  div {
    h3 {
      font-size: 1.3em;
      margin-bottom: 5px;
    }
  }
`;