import styled from "styled-components";

export const SwitchContainer = styled.div<{width?: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label span {
      left: calc(100% - 2px);
      transform: translateX(-100%);

    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: ${({ width }) => width || '60px'};
    height: 35px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 100px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    position: relative;
    transition: background-color .2s;

    span {
      content: '';
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 30px;
      height: 30px;
      border-radius: 45px;
      transition: 0.2s;
      background: #fff;
      box-shadow: 0 0 6px 0 rgba(10, 10, 10, 0.29);

      svg {
        background: none;
        fill: ${({ theme }) => theme.colors.background};
      }
    }

    span[aria-checked="true"] {
      background-color: rgb(38, 147, 230);
    }

    &:active span {
      width: 60px;
    }

  }


`;