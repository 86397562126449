import styled from "styled-components";

export const AutocompleteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    border-radius: 6px;
    max-width: 320px;
    width: 100%;
    position: relative;

    .selected {
        background: ${({ theme }) => theme.colors.softGray};
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
    }

    .autocomplete-dropdown-button {
        border: 0;
        outline: none;
        margin-right: 10px;
        cursor: pointer;
    }

    svg {
        background: none;
        fill: ${({ theme }) => theme.colors.darkGray};
        margin-left: 10px;
    }

    ul {
        list-style: none;
        position: absolute;
        max-width: 250px;
        width: 100%;
        top: calc(100% + 5px);
        overflow-y: auto;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        z-index: 999;

        li {
            border-bottom: 0.5px solid ${({ theme }) => theme.colors.lightGray};
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 40px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            font-size: 0.9em;
            padding-left: 20px;
            background: ${({ theme }) => theme.colors.background};
            &:hover {
                background: ${({ theme }) => theme.colors.softGray};
            }
        }
    }

    input {
        border: 0;
        outline: none;
        margin-left: 15px;
        width: 90%;

        &::placeholder {
            color: ${({ theme }) => theme.colors.text};
        }
    }
`;

export const AutoCompleteSelectContainer = styled.div`
  margin-bottom: 15px;
`