import styled from "styled-components";

export const LoginDetachContainer = styled.form`
  h1 {
    margin-bottom: 15px;
    font-size: 2em;
    font-weight: 500;
  }

  .detach-login-controller {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
    button {
      height: 30px;
      width: 90px;
      font-size: 1em;
    }
  }

  .detach-login-usernotfound-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    h2 {
      font-size: 1.3em;
      font-weight: 500;
      margin-bottom: 20px;
    }
    i {
      font-size: 0.9em;
    }
    div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;