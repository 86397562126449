import React from "react";
import { TabMenuContainer } from "./styles";
import MenuItems from "./config/MenuItems";

function EditUserTabMenu({
	active,
	setActive,
}: {
	active: string;
	setActive: (value: string) => void;
}): JSX.Element {
	return (
		<TabMenuContainer>
			<ul>
				{MenuItems.map((item) => {
					return (
						<li
							aria-disabled={item.disabled}
							key={item.title}
						>
							{item.disabled && (
								<div className="menu-disabled">{item.title}</div>
							)}
							{!item.disabled && (
								<div
									className={
										active && active === item.title ? "active-nav-tab-item" : ""
									}
									onClick={() => {
										setActive(item.title);
									}}
								>
									{item.icon}
									{item.title}
								</div>
							)}
						</li>
					);
				})}
			</ul>
		</TabMenuContainer>
	);
}

export default EditUserTabMenu;
