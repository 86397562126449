import styled from "styled-components";

export const PerfilContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 2% 4%;

  @media(max-width: 530px) {
    margin: 2% 0;
  }

  @media(max-width: 1140px) {
    flex-wrap: wrap;
  }

  section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  #perfil-ultimas-ideias {
    h2 {
      font-size: 2.3em;
      font-weight: 400;
    }
  }
`;

export const PerfilCard = styled.div`
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 500px;
  max-width: 650px;
  width: 100%;

  @media (max-width: 530px) {
    max-width: unset;
    min-width: unset;
    width: 100vw;
    header {
      flex-direction: column;
      gap: 10px;
      
    }
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;



    #gestao-perfil-informacoes {
      flex-grow: 1;
      padding-left: 15px;
      h1 {
        font-size: 1.5em;
        font-weight: 500;
        margin-bottom: 20px;
      }

      .roletag {
        padding: 10px;
        border-radius: 20px;
      }

      .input-message {
        width: 80px;
      }

      div {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 0.9em;
        justify-content: space-between;

        div {
          margin-top: 0;
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 1.05em;
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    span {
      font-size: 1.2em;
    }
  }

`;

export const PerfilImagem = styled.div<{url: string, letterColor: string}>`
  background: ${({ url, letterColor }) => (url ? `url(${url})` : letterColor)};
  background-size: cover;
  background-position: center;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.background};
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0
`;

export const PerfilButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 650px;
  min-width: 500px;

  @media (max-width: 520px) {
    flex-direction: column;
    min-width: 0;
    max-width: none;
    width: 100%;
    gap: 10px;

    div {
      flex-direction: column;
      gap: 10px !important;
    }
    div button {
      max-width: none;
      width: 100vw !important;
    }
  }

  div {
    display: flex;
    gap: 15px;
  }

  .red-button {
    width: 150px;
    height: 35px;
  }
`;
