import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../global";
import lightTheme from "../themes/light";
import CustomThemeContext from "./customThemeContext";
import { PersistManager } from "../../shared/persistenceManager";

const CustomThemeProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [theme, setTheme] = useState(lightTheme);
	const THEME_KEY = "IDEIAX:THEME";
	const persistenManager = new PersistManager("app");

	useEffect(() => {
		initialThemeHandler(THEME_KEY, lightTheme);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleTheme = (newTheme: typeof theme) => {
		persistenManager.set(THEME_KEY, JSON.stringify(newTheme));
		setTheme(newTheme);
	};

	const initialThemeHandler = (key: string, initialState: any) => {
		const local = persistenManager.get(key);

		if (local) {
			setTheme(JSON.parse(local));
		} else {
			setTheme(initialState);
		}
	};

	return (
		<CustomThemeContext.Provider value={{ toggleTheme, theme }}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				{children}
			</ThemeProvider>
		</CustomThemeContext.Provider>
	);
};

export function useTheme() {
	const { toggleTheme, theme } = useContext(CustomThemeContext);
	const contexto = { toggleTheme, theme };
	return contexto;
}

export default CustomThemeProvider;
