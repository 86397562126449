import styled from "styled-components";

export const StageInputContainer = styled.div<{ width?: string, height?: string }>`
  margin-bottom: 15px;
  .input-label {
    font-size: 0.8em !important;
  }
  
  .character-counter {
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 0.8em;
    color: ${({ theme }) => theme.colors.darkGray};
  }
  .stage-input-wrapper {
    position: relative;
    margin-top: 5px;
    display: flex;
    align-items: center;
    height: ${({ height }) => height ?? "35px"};
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    border-radius: 6px;
    padding-left: 15px;
    max-width: ${({ width }) => width ?? "450px"};
    width: 100%;

    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.darkGray};
    }
  }

`;
export const StageInputElement = styled.input`
  border: none;
  outline: none;
  background: none;
  width: 90%;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.text};
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const StageInputTextArea = styled.textarea`
  border: none;
  outline: none;
  background: none;
  width: 98%;
  height: 100%;
  padding: 10px;
  color: ${({ theme }) => theme.colors.text};
  resize: none;
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.text};
  }
`;
