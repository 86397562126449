import styled from "styled-components";

export const LoginSlideContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
  height: 100vh;
  width: 100%;
	position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: -5px; /* Ajuste conforme necessário */
  bottom: 0;
  width: 25px; /* Ajuste conforme necessário */
	background: linear-gradient(to left, rgba(252, 252, 251, 0), rgba(252, 252, 251, 1));
	z-index: 1000;
`;