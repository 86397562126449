import styled from "styled-components";

export const AddDimensionFormContainer = styled.form`

section {
  margin: 15px 0;
  #upload-zone {
    height: 67px;
    font-size: 0.833em;
    p {
      margin-bottom: 5px;
    }
  }
}
  .dimension-upload-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .dimension-delete-cover {
    background: none;
    width: 130px;
    height: 39px;
    padding: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    border: 0;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.softGray};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.darkGray};
      cursor: not-allowed;

      svg {
        fill: ${({ theme }) => theme.colors.darkGray};
      }
    }

    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.primary};
      margin-right: 8px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
    gap: 15px;
  }
`;

export const AddDivider = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const AddDimensionCriteria = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;