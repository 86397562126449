import React, { useEffect, useState } from "react";
import {
	EditUserFormContainer,
	EditarFormButtonsWrapper,
	EditarFormDivider,
} from "./styles";
import UserInput from "../../UserInput";
import AutoCompleteSelect from "../../../../../shared/util/Autocomplete";
import { OptionValue } from "../../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../../shared/provider/RoleProvider";
import TenantProvider from "../../../../../shared/provider/TenantProvider";
import {
	FaUser,
	FaEnvelope,
	FaSearch,
	FaCheck,
	FaLock,
	FaIdCard,
} from "react-icons/fa";
import Switch from "../../../../../shared/util/Switch";
import DefaultButton from "../../../../../shared/util/DefaultButton";
import { toast } from "react-toastify";
import { CreateUserDto } from "../../../../../shared/dto/CreateUserDto";
import AuthProvider from "../../../../../shared/provider/AuthProvider";
import validateUserCreationDto from "./validation/validateUserCreationDto";

function AdicionarUsuarioForm({
	setOpen,
	isLoading = false,
	setLoading,
	setSuccessMessage,
	requireUserList,
}: {
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
	setLoading: React.Dispatch<boolean>;
	setSuccessMessage: React.Dispatch<string>;
	requireUserList: () => Promise<void>;
}) {
	const authProvider = new AuthProvider();
	const [nome, setNome] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [cpf, setCpf] = React.useState("");

	const [roleOptions, setRoleOptions] = useState([] as Array<OptionValue>);
	const [tenantOptons, setTenantOptions] = useState([] as Array<OptionValue>);

	const roleProvider = new RoleProvider();
	const tenantProvider = new TenantProvider();

	const [selectedRoleOption, setSelectedRoleOption] = useState(
		{} as { value: string; label: string }
	);

	const [selectedTenantOption, setSelectedTenantOption] = useState(
		{} as { value: string; label: string }
	);

	const [selectedActiveOption, setSelectedActiveOption] = useState(
		{} as { value: string; label: string }
	);

	const ActiveOptions = [
		{ value: "true", label: "Ativo" },
		{ value: "false", label: "Inativo" },
	];

	const [roleValue, setRoleValue] = useState(selectedRoleOption.label || "");
	const [tenantValue, setTenantValue] = useState(
		selectedTenantOption.label || ""
	);
	const [activeValue, setActiveValue] = useState("");

	function clearFields() {
		setNome("");
		setEmail("");
		setCpf("");
		setSelectedRoleOption({} as { value: string; label: string });
		setSelectedTenantOption({} as { value: string; label: string });
		setRoleValue("");
		setTenantValue("");
	}

	async function createUser(data: CreateUserDto) {
		setLoading(true);
		const response = await authProvider.register(data);
		if (response) {
			await requireUserList();
			setSuccessMessage("Usuário cadastrado com sucesso!");
			clearFields();
		}
		setLoading(false);
	}

	async function requireRoleOptions() {
		try {
			const data = await roleProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setRoleOptions(data.data);
			return data;
		} catch (error) {
			console.error("Erro ao buscar roles:", error);
			return [] as Array<OptionValue>;
		}
	}

	async function requireTenantOptions() {
		try {
			const data = await tenantProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setTenantOptions(data);
			return data;
		} catch (error) {
			console.error("Erro ao buscar tenants:", error);
			return [] as Array<OptionValue>;
		}
	}

	async function onInit() {
		await requireRoleOptions();
		await requireTenantOptions();
	}

	useEffect(() => {
		onInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (
			!nome ||
			!email ||
			!cpf ||
			!selectedRoleOption.value ||
			!selectedTenantOption.value
		) {
			toast.error("Preencha todos os campos");
			return;
		}

		const erros = validateUserCreationDto({
			name: nome,
			email,
			tenantId: selectedTenantOption.value,
			roleId: selectedRoleOption.value,
			document: cpf,
		});

		if (erros.length) {
			erros.forEach((erro) => toast.error(erro));
			return;
		}

		const nomeCompleto = nome.trim();
		const nomeArray = nomeCompleto.split(" ");
		const nomeUsuario = nomeArray[0];
		const sobrenomeUsuario = nomeArray.slice(1).join(" ");

		const newUser = {
			nome: nomeUsuario,
			sobrenome: sobrenomeUsuario,
			email,
			cpf,
			roleId: selectedRoleOption.value,
			tenantId: selectedTenantOption.value,
			ativo: selectedActiveOption.value === "true" ? true : false,
		};

		createUser(newUser);
	}

	return (
		<EditUserFormContainer onSubmit={handleSubmit}>
			<UserInput
				value={nome}
				setValue={setNome}
				label="Nome:"
				placeholder="Nome completo"
				message="Digite o nome pelo qual o usuário deseja ser identificado"
				icon={<FaUser />}
				type="username"
				width="100%"
				isLoading={isLoading}
				regex={/[^a-zA-Z\sÀ-ÖØ-öø-ÿ]/g}
			/>

			<UserInput
				value={cpf}
				setValue={setCpf}
				label="CPF"
				placeholder="Digite aqui..."
				message="Digite o CPF do usuário"
				icon={<FaIdCard />}
				type="text"
				isDocument={true}
			/>
			<div className="double-input">
				<UserInput
					value={email}
					setValue={setEmail}
					label="E-mail:"
					placeholder="Ex: seuemail@gov.br"
					message="Digite o e-mail corporativo mais utilizado"
					icon={<FaEnvelope />}
					type="email"
					width="100%"
					isLoading={isLoading}
					id={"adicionar-email-input"}
				/>

				<AutoCompleteSelect
					inputValue={roleValue}
					setInputValue={setRoleValue}
					options={roleOptions}
					item={selectedRoleOption}
					setItem={setSelectedRoleOption}
					message="Define o tipo de acesso desse usuário"
					icon={<FaSearch />}
					showDropdownButton={true}
					label="Perfil:"
					isLoading={roleOptions.length === 0 || isLoading}
				/>
			</div>

			<div className="double-input">
				<AutoCompleteSelect
					inputValue={tenantValue}
					setInputValue={setTenantValue}
					options={tenantOptons}
					item={selectedTenantOption}
					setItem={setSelectedTenantOption}
					message="Grupo ao qual este usuário vai pertencer"
					icon={<FaSearch />}
					showDropdownButton={true}
					label="Tenants"
					isLoading={tenantOptons.length === 0 || isLoading}
				/>

				<AutoCompleteSelect
					inputValue={activeValue}
					options={ActiveOptions}
					setInputValue={setActiveValue}
					item={selectedActiveOption}
					setItem={setSelectedActiveOption}
					message="Define se um usuário estará ativo ou não"
					showDropdownButton={true}
					label="Status"
				/>
			</div>

			<EditarFormDivider />

			<EditarFormButtonsWrapper>
				<div>
					<DefaultButton
						width="220px"
						height="35px"
						fontSize="1em"
						params={{
							type: "button",
							onClick: () => clearFields(),
						}}
						withOutBackground={true}
					>
						Limpar todos os campos
					</DefaultButton>

					<DefaultButton
						width="215px"
						height="35px"
						fontSize="1em"
						params={{
							type: "submit",
							disabled: isLoading,
						}}
					>
						Cadastrar novo usuário
					</DefaultButton>
				</div>
			</EditarFormButtonsWrapper>
		</EditUserFormContainer>
	);
}

export default AdicionarUsuarioForm;
