import { FaLightbulb, FaUser } from "react-icons/fa";

const MenuItems = [
	{
		title: "Meus dados",
		icon: <FaUser />,
		disabled: false,
	},
	{
		title: "Minhas Ideias",
		icon: <FaLightbulb />,
		disabled: false,
	},
];

export default MenuItems;
