import React from "react";
import { AdicionarCategoryContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import AddCategoryForm from "./components/addCategoryForm";
import DefaultButton from "../../../../../../shared/util/DefaultButton";

function AddCategory({
	open,
	setOpen,
	requireCategoryList,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	requireCategoryList(): Promise<void>;
}>) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	return (
		<AdicionarCategoryContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="adicionar-category-modal-container"
				title={!successMessage ? <h2>Criar categoria</h2> : <></>}
				closeButtonCb={!successMessage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMessage && (
					<AddCategoryForm
						setOpen={setOpen}
						isLoading={loading}
						setLoading={setLoading}
						setSuccessMessage={setSuccessMessage}
						requireCategoryList={requireCategoryList}
					/>
				)}

				{successMessage && (
					<div className="edit-category-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</AdicionarCategoryContainer>
	);
}

export default AddCategory;
