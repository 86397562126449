import React, { useEffect, useState } from "react";
import { AutoCompleteSelectContainer, AutocompleteContainer } from "./styles";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface AutoCompleteSelectProps {
	options: { value: string; label: string }[];
	item: { value: string; label: string };
	setItem: React.Dispatch<{ value: string; label: string }>;
	icon?: JSX.Element;
	showDropdownButton?: boolean;
	placeholder?: string;
	label?: string;
	message?: string;
	inputValue: string;
	setInputValue: React.Dispatch<string>;
	lockDropdown?: boolean;
	isLoading?: boolean;
}
function AutoCompleteSelect(props: AutoCompleteSelectProps) {
	const { inputValue: value, setInputValue: setValue } = props;
	const [filteredOptions, setFilteredOptions] = useState(
		props.options || ([] as { value: string; label: string }[])
	);
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const dropdownRef = React.useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const selectedIndexRef = React.useRef(selectedIndex);
	selectedIndexRef.current = selectedIndex;

	const selectRef = React.useRef<HTMLInputElement>(null);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (dropdownOpened) {
				switch (event.key) {
					case "ArrowDown":
						event.preventDefault();
						setSelectedIndex(
							(prevState) => (prevState + 1) % filteredOptions.length
						);
						break;
					case "ArrowUp":
						event.preventDefault();
						setSelectedIndex(
							(prevState) =>
								(prevState - 1 + filteredOptions.length) %
								filteredOptions.length
						);
						break;
					case "Enter":
						event.preventDefault();
						handleSelectOption(filteredOptions[selectedIndexRef.current]);
						break;
					default:
						break;
				}
			}
		};

		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [dropdownOpened, filteredOptions.length]);

	useEffect(() => {
		if (selectRef.current === document.activeElement) {
			if (value && value.length > 0) {
				setDropdownOpened(true);
			} else {
				setDropdownOpened(false);
			}
		}
	}, [value]);

	useEffect(() => {
		if (filteredOptions && !filteredOptions.length) {
			setDropdownOpened(false);
		}
	}, [filteredOptions]);

	useEffect(() => {
		if ((value && value.length && value === props.item.label) || !value) {
			setFilteredOptions(props.options);
		}
	}, [props.item, value]);

	useEffect(() => {
		if (props.item && props.item.label && !value) {
			setValue(props.item.label);
		}
	}, [props.item]);

	useEffect(() => {
		if (props.options && props.options.length) {
			setFilteredOptions(props.options);
		}
	}, [props.options]);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setDropdownOpened(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setValue(inputValue);

		const filtered = props.options.filter((option) =>
			removeAccents(option.label.toLowerCase()).includes(
				removeAccents(inputValue.toLowerCase())
			)
		);
		setFilteredOptions(filtered);
	};

	function removeAccents(str: string) {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	function getItemById(name: string) {
		return (
			props.options.find((option) => option.label === name) || {
				value: "",
				label: "",
			}
		);
	}

	const handleSelectOption = (option: { value: string; label: string }) => {
		props.setItem(option);
		setValue(option.label);
		setFilteredOptions([]);
		setDropdownOpened(false);
	};

	function onBlurHandler() {
		if (value && value.length && !props.item.label) {
			setValue("");
		} else if (!value) {
			if (props.item && props.item.label) {
				setValue(props.item.label);
				setTimeout(() => setDropdownOpened(false), 150);
			}
		} else if (
			value &&
			value.length &&
			props.item.label &&
			value !== props.item.label
		) {
			const option = getItemById(value);
			if (option.value) {
				handleSelectOption(option);
			} else {
				setValue("");
			}
		}
	}

	/* if (props.isLoading) {
		return <AutoCompleteSelectContainer className="skeleton-loading" />;
	} */

	return (
		<AutoCompleteSelectContainer className="autocomplete-form">
			{props.label && <span className="input-label">{props.label}</span>}
			<AutocompleteContainer
				ref={dropdownRef}
				className={`autocomplete-selects ${props.isLoading ? "skeleton-loading" : ""}`}
			>
				<div>
					{props.icon && props.icon}
					<input
						ref={selectRef}
						type="text"
						value={value}
						onChange={(e) => handleChange(e)}
						onBlur={onBlurHandler}
						placeholder={props.placeholder || "Selecione ..."}
					/>
				</div>

				{props.showDropdownButton && (
					<button
						type="button"
						className="autocomplete-dropdown-button"
						title="Abrir opções"
						onClick={() => setDropdownOpened(!dropdownOpened)}
					>
						{dropdownOpened ? <FaChevronUp /> : <FaChevronDown />}
					</button>
				)}

				{!props.lockDropdown && dropdownOpened && (
					<ul>
						{filteredOptions.map((option, index) => (
							<li
								key={index}
								onClick={() => handleSelectOption(option)}
								className={index === selectedIndex ? "selected" : ""}
							>
								{option.label}
							</li>
						))}
					</ul>
				)}
			</AutocompleteContainer>
			{props.message && <span className="input-message">{props.message}</span>}
		</AutoCompleteSelectContainer>
	);
}

export default AutoCompleteSelect;
