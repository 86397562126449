import styled from "styled-components";

export const UserInputContainer = styled.div<{ width?: string, readOnly?: boolean }>`
  margin-bottom: 15px;
  .user-input-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    background: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    border-radius: 6px;
    padding-left: 15px;
    max-width: ${({ width }) => width || "450px"};
    width: 100%;
    opacity: ${({ readOnly }) => (readOnly ? 0.6 : 1)};

    svg {
      background: none;
      fill: ${({ theme }) => theme.colors.darkGray};
    }
  }

`;
export const UserInputElement = styled.input`
  border: none;
  outline: none;
  background: none;
  width: 90%;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.text};
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.text};
  }

  &:read-only {
    opacity: 0.6;
  }
`;
