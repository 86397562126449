import React from "react";
import { IdeiaContainer } from "./styles";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { useIdeia } from "../../../shared/context/ideia/provider";
import PermissionValidator from "../../../shared/guard/PermissionValidator";
import { PERMISSIONS } from "../../../shared/guard/Permissions";

function Ideia(): JSX.Element {
 const { setOpenModal, openModal } = useIdeia();
 const permissionValidator = new PermissionValidator();
 function handleCreateIdea() {
  setOpenModal(!openModal);
 }
 return (
   <IdeiaContainer>
     {permissionValidator.hasPermission(PERMISSIONS.INSERIR_IDEIA) && (
      !openModal && (
       <div id="criar-nova-ideia-btn">
        <Button
         type="primary"
         shape="circle"
         style={{ width: "100px", height: "100px" }}
         icon={
           <div className="icon-container">
             <FontAwesomeIcon
               icon={faPlus}
               className="icon"
               id="plus-icon"
               style={{ fontSize: "28px", color: "#fff", fontFamily: "FontAwesome" }}
             />
             <FontAwesomeIcon
               icon={faLightbulb}
               className="icon"
               id="lightbulb-icon"
               style={{ fontSize: "40px", color: "#fff", fontFamily: "FontAwesome" }}
             />
           </div>
         }
         onClick={handleCreateIdea}
        />
       <span id="criar-nova-ideia-span">{ "Nova ideia" }</span>
      </div>
      )
     )}
   </IdeiaContainer>
 );
}

export default Ideia;