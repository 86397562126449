const lightTheme = {
  title: 'light',

  colors: {
    primary: '#1351b4',
    secundary: '#6f7a41 ',
    background: '#fcfcfb',
    text: '#303030',
    deny: '#e41d3d',
    headerText: '#000000',
    listHeader: '#f0f0f0',
    iconInsideInput: '#888888',
    softGray: '#f1f1f1',
    lightGray: '#D3D3D3',
    darkGray: '#888888',
  }
}

export default lightTheme;
