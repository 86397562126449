import React from "react";
import { ExcluirDimensionContainer } from "./styles";
import DimensionProvider from "../../../../../../shared/provider/DimensionProvider";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { DimensionDto } from "../../../../../../shared/dto/DimensionDto";

function DeleteDimension({
	open,
	setOpen,
	dimension,
	onFinished,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	dimension: DimensionDto;
	onFinished?: () => void;
}>) {
	const [successMessage, setSuccessMessage] = React.useState("");
	const dimensionProvider = new DimensionProvider();
	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	async function handleExcluirDimension() {
		if (dimension && dimension.dimensionId) {
			try {
				await dimensionProvider.delete(dimension.dimensionId);
				setSuccessMessage("Dimensão excluido com sucesso.");
			} catch (error) {
				console.error("Erro ao excluir categoria:", error);
			}
		}
	}

	return (
		<ExcluirDimensionContainer>
			<Modal
				open={open}
				setOpen={setOpen}
			>
				{!successMessage && (
					<>
						<h3>Excluir dimensão "{dimension.title}"?</h3>

						<footer>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								withOutBackground={true}
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Voltar
							</DefaultButton>
							<DefaultButton
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: handleExcluirDimension,
								}}
							>
								Excluir
							</DefaultButton>
						</footer>
					</>
				)}

				{successMessage && (
					<div className="edit-user-success-step">
						<h2 className="centered-message">{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="115px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => {
										setOpen(false);
										onFinished && onFinished();
									},
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</ExcluirDimensionContainer>
	);
}

export default DeleteDimension;