import { CreateIdeiaDto } from "../../../../shared/provider/IdeaProvider";

interface AddModalValidation {
  field: string;
  message: string;
}

export function addModalValidation(data: CreateIdeiaDto, target: 'edit' | 'insert'): AddModalValidation[] {
  const errors: AddModalValidation[] = [] as AddModalValidation[];

  if (target === 'edit' && !data.idea.ideaId) {
    errors.push({ field: "ideaId", message: "Erro ao recuperar identificador da idéia" });
    return errors;
  }

  if (!data.idea.title) {
    errors.push({ field: "title", message: "O campo título é obrigatório" });
  }

  if (!data.idea.shortDescription) {
    errors.push({ field: "shortDescription", message: "O campo descrição é obrigatório" });
  }

  if (!data.idea.reportedProblem) {
    errors.push({ field: "reportedProblem", message: "O campo problema relatado é obrigatório" });
  }

  if (!data.idea.solution) {
    errors.push({ field: "solution", message: "O campo solução é obrigatório" });
  }

  if (!data.idea.category?.categoryId) {
    errors.push({ field: "category", message: "O campo categoria é obrigatório" });
  }

  return errors;
}