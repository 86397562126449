import styled from "styled-components";

export const HeaderContainer = styled.header`
  position: relative;
  z-index: 1000;
  margin-bottom: 15px;
`;

export const HeaderHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 4%;

  div {
    display: flex;
  }

  .header-head-left {
    div:nth-child(2) {
      height: 35px;
      border-left: 0.5px solid ${({ theme }) => theme.colors.darkGray};
      margin: 0 10px;
      padding-left: 10px;
      align-items: flex-start;
    }
  }

  .header-head-right {
    .header-accessibility {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      color: ${({ theme }) => theme.colors.primary};
      gap: 10px;
      margin-right: 25px;
      font-size: 1em;

      li {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &:hover {
          background: ${({ theme }) => theme.colors.lightGray};
        }
      }

      svg { 
        background: none;
        path {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    span {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1em;
      font-weight: 500;
      margin-right: 10px;
      margin-top: 3px;
      cursor: pointer;
    }
  }

  .header-gov-logo {
    background: url("/assets/images/logo_ideiax_nova.png") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100px;
    height: 42px;
    border: 0;
    cursor: pointer;
  }

  @media(max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  top: 0;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
  padding: 12px 4%;
  justify-content: space-between;

  section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header-title {
    margin-left: 15px;
    h2 {
      font-weight: 400;
    }
  }

  .header-side-menu-button {
      background: none;
      cursor: pointer;
      margin: 10px;
      transition: all 0.2s ease-in-out;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      svg {
        background: none;
        font-size: 1.4em;
        path {
          color: ${({ theme }) => theme.colors.primary};
        }
      }

      &:hover {
          background: ${({ theme }) => theme.colors.lightGray};
      }
  }
`;

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 4%;
  margin-top: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  @media (max-width: 768px) {
      overflow-x: auto;
      overflow-y: hidden;
    }

  section {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    max-width: 300px;
    width: 100%;
    span {
      font-weight: 600;
    } 
  }
`;