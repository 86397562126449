import React from "react";
import { IdeiaDto } from "../../dto/IdeiaDto";
import { MetaSimplificadoDto } from "../../dto/MetaSimplificadoDto";
import { MessageInstance } from "antd/es/message/interface";

interface MinhaIdeiaContextData {
  ideias: IdeiaDto[];
  ideia: IdeiaDto;
  setIdeia: (ideia: IdeiaDto) => void;
  loading: boolean;
  openModal: boolean;
  page: number;
  itemsPerPage: number;
  totalItems: number;
  setItemsPerPage: (itemsPerPage: number) => void;
  openView: boolean;
  openEdit: boolean;
  messageApi: MessageInstance;
  images: MetaSimplificadoDto[];
  getIdeaImages(id: string): Promise<void>;
  setImages: (images: MetaSimplificadoDto[]) => void;
  setOpenView: (open: boolean) => void;
  setOpenEdit: (open: boolean) => void;
  setPage: (page: number) => void;
  setOpenModal: (open: boolean) => void
  setIdeias: (ideias: IdeiaDto[]) => void;
  loadIdeias({ page, itemsPerPage }: { page?: number; itemsPerPage?: number }): Promise<void>;
}

const MinhaIdeiaContext = React.createContext<MinhaIdeiaContextData>({} as MinhaIdeiaContextData);

export { MinhaIdeiaContext };