const MenuItems = [
	{
		title: "Permissões",
	},
	{
		title: "Desafios",
		disabled: true,
	},
	{
		title: "Categorias",
	},
	{
		title: "Dimensão",
	},
	{
		title: "Fases",
	},
	{
		title: "Relatório",
		disabled: true,
	},
];

export default MenuItems;
