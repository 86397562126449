import React from "react";
import { FooterAcessoInformacaoImage, FooterContainer } from "./styles";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<FooterContainer>
			<head>
				<div>
					<Link to="/">
						<img
							src="/assets/images/logo_ideiax_nova.png"
							alt="logotipo da Ideia X"
						/>
					</Link>
				</div>
				<h3>PLATAFORMA DE INOVAÇÃO</h3>
				<nav>
					<ul>
						<li>Termos de uso</li>
						<li>Política de privacidade</li>
					</ul>
				</nav>
			</head>
			<section>
				<Link to="/">
					<FooterAcessoInformacaoImage image="/assets/images/acesso_informacao.png" />{" "}
				</Link>
				<span>© Ideia X {new Date().getFullYear()}</span>
			</section>
		</FooterContainer>
	);
}
export default Footer;
