import React from "react";
import { EditarCategoryContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import EditCategoryForm from "./components/editCategoryForm";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import { CategoryDto } from "../../../../../../shared/dto/CategoryDto";
import ShowCategory from "./components/showCategory";

function EditCategory({
	open,
	setOpen,
	handleDeleteCategoryClick,
	requireCategoryList,
	category,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	handleDeleteCategoryClick: (category: CategoryDto) => void;
	requireCategoryList(): Promise<void>;
	category: CategoryDto;
}>) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");
	const [editMode, setEditMode] = React.useState(false);

	const isEditMode = editMode ? <h2>Editar categoria</h2> : <h2>Categoria</h2>;

	React.useEffect(() => {
		if (open) {
			setEditMode(false);
			setSuccessMessage("");
		}
	}, [open]);

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	const title = !successMessage ? isEditMode : <></>;

	return (
		<EditarCategoryContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="adicionar-category-modal-container"
				title={title}
				closeButtonCb={!successMessage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMessage && !editMode && (
					<ShowCategory
						setOpen={setOpen}
						category={category}
						setEditMode={setEditMode}
						handleDeleteCategoryClick={handleDeleteCategoryClick}
					/>
				)}

				{!successMessage && editMode && (
					<EditCategoryForm
						category={category}
						setOpen={setOpen}
						isLoading={loading}
						setLoading={setLoading}
						setSuccessMessage={setSuccessMessage}
						requireCategoryList={requireCategoryList}
						setEditMode={setEditMode}
					/>
				)}

				{successMessage && (
					<div className="edit-category-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</EditarCategoryContainer>
	);
}

export default EditCategory;
