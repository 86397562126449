import styled from "styled-components";

export const TabMenuContainer = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
    margin-bottom: 15px;
    min-width: 600px;
    li {
      flex-shrink: 0;

      a {
        padding: 15px 24px;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.text};
        svg {
          background: none;
          margin-right: 10px;
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.lightGray};
        }
        &:visited {
          color: unset;
        }
      }
    }
    .active-nav-tab-item {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
`;