import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  createButton,
  BtnStyles,
  Editor,
  EditorProvider,
  Toolbar,
  ContentEditableEvent
} from 'react-simple-wysiwyg';
import React, { useState, useEffect } from 'react';

const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');
const BtnBulletPoints = createButton('Bullet points', '•', 'insertUnorderedList');

interface CustomEditorProps {
    value: string;
    onChange: (value: any) => void;
    showCharCount?: boolean;
    charLimit?: number;
    style?: React.CSSProperties; // Add style property
}

export default function CustomEditor({ value, onChange, showCharCount = false, charLimit, style }: CustomEditorProps) {
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(value.length);
  }, [value]);

  const handleChange = (newValue: ContentEditableEvent) => {
    if (charLimit && newValue.target.value.length > charLimit) {
      return;
    }
    onChange(newValue);
  };

  return (
      <EditorProvider>
        <Editor value={value} onChange={handleChange} containerProps={{ style: { ...style} }}>
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnAlignCenter />
            <BtnBulletPoints />
          </Toolbar>
        </Editor>
        {showCharCount && (
            <div style={{ textAlign: 'right', marginTop: 8, color: "rgba(0, 0, 0, 0.45)" }}>
              {charCount}/{charLimit || '∞'}
            </div>
        )}
      </EditorProvider>
  );
}