import { TableColumnsType } from "antd";
import { StageItem } from "../../../../../../../shared/dto/StageItem";

const columns: TableColumnsType<StageItem> = [
  {
    title: 'Fase',
    dataIndex: 'title',
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 500, width: "100%"}}>{text}</div>,
    sorter: (a, b) => a.title.localeCompare(b.title),
    width: 150,
  },
  {
    title: 'Descrição',
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 800, width: "100%"}}>{text}</div>,
    dataIndex: 'description',
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    title: 'Ordem',
    dataIndex: 'order',
    sorter: (a, b) => a.order - b.order,
    width: 100,
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    width: 250,
  },
]

export default columns;