import React from "react";
import { CheckboxContainer, StyledCheckbox, StyledLabel } from "./styles";
import { FaCheck } from "react-icons/fa";

interface CheckboxProps {
	label?: string;
	checked: boolean;
	onChange: (e: string) => void;
	checkboxKey: string;
}

function Checkbox(props: CheckboxProps): JSX.Element {
	return (
		<CheckboxContainer>
			<StyledCheckbox
				id={props.checkboxKey}
				checked={props.checked}
				onChange={() => props.onChange(props.checkboxKey)}
			/>
			<StyledLabel htmlFor={props.checkboxKey}>
				{props.checked ? <FaCheck /> : ""}
			</StyledLabel>
			{props.label && <span>{props.label}</span>}
		</CheckboxContainer>
	);
}

export default Checkbox;
