import React from "react";
import { StageListContainer } from "./styles";
import StageListItem from "./components/StageList";

function StageList({
	openAddStage,
	setOpenAddStage,
}: Readonly<{
	openAddStage: boolean;
	setOpenAddStage: React.Dispatch<React.SetStateAction<boolean>>;
}>): JSX.Element {
	return (
		<StageListContainer>
			<StageListItem
				openAddStage={openAddStage}
				setOpenAddStage={setOpenAddStage}
			/>
		</StageListContainer>
	);
}

export default StageList;
