import { TableColumnsType } from "antd";
import { DimensionItem } from "../../../../../../../shared/dto/DimensionItem";

const columns: TableColumnsType<DimensionItem> = [
  {
    title: 'Título',
    dataIndex: 'title',
    sorter: (a, b) => a.title.localeCompare(b.title),
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 500, width: "100%"}}>{text}</div>,
    width: 135,
  },
  {
    title: 'Descrição',
    dataIndex: 'description',
    render: (text) => <div style={{ overflowX: "auto", maxWidth: 800, width: "100%"}}>{text}</div>,
    sorter: (a, b) => a.description.localeCompare(b.description),
  },
  {
    title: 'Peso',
    dataIndex: 'weight',
    sorter: (a, b) => a.weight - b.weight,
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    width: 250,
  },
]

export default columns;