import { DimensionCategoryDto } from '../dto/DimensionCategoryDto';
import { DimensionDto } from '../dto/DimensionDto';
import { IPageData } from '../dto/PageData';
import Envoy from '../util/Envoy';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
}

class DimensionProvider extends Envoy {
  public async getAll(
    {page,
    itemsPerPage,}
      : IPaginationDTO): Promise<IPageData<Array<DimensionDto>>> {
      try {
        const paginationString = this.buildQueryString({page, itemsPerPage});

        const response = await this.conn.get(`/dimension${paginationString}`);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));

        return response.data.data;
      } catch (error) {
        console.error('Erro ao obter todas as dimensões:', error);
        throw error;
      }
    }

  public async createOrUpdate(dimension: DimensionDto): Promise<DimensionDto> {
    try {
      const response = await this.conn.post('/dimension', dimension);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao criar dimensão:', error);
      throw error;
    }
  }

  public async createOrUpdateCriteria({
    criteria, dimensionId
  } : {
    criteria: Array<DimensionCategoryDto>;
    dimensionId: string;
  }): Promise<DimensionDto> {
    try {
      const response = await this.conn.post(`/dimension/criteria/${dimensionId}`, criteria);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao atualizar critério:', error);
      throw error;
    }
  }

  public async delete(dimensionId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/dimension/${dimensionId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar dimensão:', error);
      throw error;
    }
  }

  public async deleteCriteria(criteria: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/dimension/criteria/${criteria}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar dimensão:', error);
      throw error;
    }
  }

  public async deleteCriterias(criteriaIds: string[]): Promise<void> {
    try {
      const response = await this.conn.delete(`/dimension/criterias/`, {data: criteriaIds});
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar dimensão:', error);
      throw error;
    }
  }
  
}

export default DimensionProvider;