import React, { useState } from "react";
import { IPageData } from "../../../../shared/dto/PageData";
import { IUsuarioSimplificado } from "../../../../shared/dto/UsuarioSimplificado";
import { SearchInputContainer, SearchInputField } from "./styles";
import { FaSearch } from "react-icons/fa";
import UserProvider from "../../../../shared/provider/UserProvider";
import { useNavigate } from "react-router-dom";

function SearchInput({
	setListPaginated,
}: {
	setListPaginated: React.Dispatch<
		React.SetStateAction<IPageData<Array<IUsuarioSimplificado>>>
	>;
}) {
	const [searchString, setSearchString] = useState<string>("");
	const userProvider = new UserProvider();
	const navigator = useNavigate();
	async function handleSearch(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		try {
			const data = searchString
				? await userProvider.search({
						page: 1,
						itemsPerPage: 10,
						query: searchString,
					})
				: await userProvider.getAllUsers({ page: 1, itemsPerPage: 10 });
			if (!data) return false;

			setListPaginated(data);
		} catch (error: any) {
			if (error && error.response && error.response.status === 403) {
				navigator("/entrar");
			}
		}
	}

	return (
		<SearchInputContainer onSubmit={(e) => handleSearch(e)}>
			<SearchInputField
				onChange={(e) => setSearchString(e.target.value)}
				placeholder="Buscar por nome ou e-mail"
			/>
			<button type="submit">
				<FaSearch />
			</button>
		</SearchInputContainer>
	);
}

export default SearchInput;
