import React, { useEffect, useState } from "react";
import {
	ViewUserFormContainer,
	VisualizarFormButtonsWrapper,
	VisualizarFormDivider,
} from "./styles";
import { IUsuarioSimplificado } from "../../../../../../../dto/UsuarioSimplificado";
import DefaultButton from "../../../../../../DefaultButton";

function VisualizarUsuarioForm({
	user,
	setOpen,
	isLoading = false,
}: Readonly<{
	user: IUsuarioSimplificado;
	setOpen: React.Dispatch<boolean>;
	isLoading?: boolean;
}>) {
	const [email] = React.useState(user.email || "");
	const [active] = React.useState(user.active || false);
	const [role] = React.useState(localStorage.getItem("role") ?? "");
	const [tenant] = React.useState(localStorage.getItem("tenantName") ?? "");

	return (
		<ViewUserFormContainer>
			<VisualizarFormDivider />

			<main>
				<div>
					<div>
						<span className="input-label">E-mail</span>
						{email}
					</div>

					<div>
						<span className="input-label">Perfíl</span>
						{role}
					</div>
				</div>

				<div>
					<div>
						<span className="input-label">Tenant</span>
						{tenant}
					</div>

					<div>
						<span className="input-label">Status</span>
						{active ? "Ativo" : "Inativo"}
					</div>
				</div>
			</main>

			<VisualizarFormDivider />

		</ViewUserFormContainer>
	);
}

export default VisualizarUsuarioForm;
