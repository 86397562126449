import styled from "styled-components";

export const EditStageFormContainer = styled.form`

  section {
    margin: 15px 0;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
    gap: 15px;
  }
`;

export const EditDivider = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;


