import styled from "styled-components";

export const AddModalContainer = styled.div``;

export const AddModalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-right: 5px;
    max-height: 600px;
    overflow-y: auto;

    label {
        font-size: 15px;
        margin-bottom: 3px;
    }

    .ant-input {
        white-space: pre-wrap;
    }
`;