import styled from "styled-components";

export const ViewUserFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4%;

  main {
    padding: 6%;
    margin: 0 20px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.softGray};
    display: flex;
    flex-direction: column;
    
    div {
      background: none;
      display: flex;
      width: 100%;
      div {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
      }
      span {
        background: none;
      }
    }
  }

`;

export const VisualizarFormDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 2% 0;
`;

export const VisualizarFormButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 4%;
  @media(max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      button {
        max-width: none;
        width: 100%;
      }

      &:first-child {
        width: 100%;
        margin-top: 15px;
        button {
          width: 100% !important;
        }
      }
    }

  }

  div {
    display: flex;
    gap: 15px;

    &:first-child {
      button {
        background-color: red;
        color: ${({ theme }) => theme.colors.background};
        border: none;
        border-radius: 30px;
        font-size: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 150px;
        font-weight: 500;
        cursor: pointer;

        &:disabled {
          background-color: #d3d3d3;
          color: #a9a9a9;
          border: 0;
          cursor: not-allowed;
        }
      }
    }

    &:nth-child(2) {
      min-width: 320px;
    }
  }
`;