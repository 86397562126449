import React from "react";
import {ExcluirStageContainer} from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import {StageDto} from "../../../../../../shared/dto/StageDto";
import StageProvider from "../../../../../../shared/provider/StageProvider";

function DeleteStage({
                         open,
                         setOpen,
                         stage,
                         onFinished,
                     }: Readonly<{
    open: boolean;
    setOpen: React.Dispatch<boolean>;
    stage: StageDto;
    onFinished?: () => void;
}>) {
    const [successMessage, setSuccessMessage] = React.useState("");
    const stageProvider = new StageProvider();
    React.useEffect(() => {
        if (!open) {
            setSuccessMessage("");
        }
    }, [open]);

    async function handleExcluirStage() {
        if (stage && stage.stageId) {
            try {
                await stageProvider.delete(stage.stageId);
                setSuccessMessage("Fase excluida com sucesso.");
            } catch (error) {
                console.error("Erro ao excluir fase:", error);
            }
        }
    }

    return (
        <ExcluirStageContainer>
            <Modal
                open={open}
                setOpen={setOpen}
            >
                {!successMessage && (
                    <>
                        <div className="centered-message">
                            <h3>Excluir fase "{stage.title}"?</h3>
                        </div>

                        <footer>
                            <DefaultButton
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                withOutBackground={true}
                                params={
                                    {
                                        onClick: () => setOpen(false),
                                    }
                                }
                            >
                                Voltar
                            </DefaultButton>
                            <DefaultButton
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                params={{
                                    onClick: handleExcluirStage,
                                }}
                            >
                                Excluir
                            </DefaultButton>
                        </footer>
                    </>
                )}

                {successMessage && (
                    <div className="edit-user-success-step">
                        <div className="success-message-container">
                            <h2>{successMessage}</h2>
                        </div>
                        <div>
                            <DefaultButton
                                withOutBackground={true}
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                params={{
                                    onClick: () => {
                                        setOpen(false);
                                        onFinished && onFinished();
                                    },
                                }}
                            >
                                Fechar
                            </DefaultButton>
                        </div>
                    </div>
                )}
            </Modal>
        </ExcluirStageContainer>
    );
}

export default DeleteStage;