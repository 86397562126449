import { FaTrash } from "react-icons/fa";
import { RoleItem } from "../../../../../shared/dto/RoleItem";
import { Tooltip } from "antd";
import { RoleOptionValue } from "../../../../../shared/dto/OptionValue";

interface MapProps {
  dto: RoleOptionValue[];
  deleteFn: (dimension: RoleOptionValue) => void;
  viewFn: (dimension: RoleOptionValue) => void;
}

function mapRoleDtoListToItems({ dto, deleteFn, viewFn } : MapProps): RoleItem[] {
  return dto.map((item) => ({
    ...item,
    action: <>
    <Tooltip title="Deletar perfil">
      <span
        className="list-delete-button"
        onClick={() => deleteFn(item)}
      >
        <FaTrash />
      </span>
    </Tooltip>
    <span
      className="list-editar-button"
      onClick={() => viewFn(item)}
    >
      Visualizar
    </span>
    </>
  }));
}

export default mapRoleDtoListToItems;