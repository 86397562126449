import styled from "styled-components";

export const UploadAreaContainer = styled.div`
  width: 100%;
  min-height: 40px;
  max-height: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 6px;
  cursor: pointer;

  div {
    font-size: 0.8em;
  }

  label {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    svg {
      fill: ${({ theme }) => theme.colors.primary};
      background: none;
    }
  }
`;