import React from "react";
import { LogoContainer } from "./styles";

function Logo(width?: string, height?: string) {
	return (
		<LogoContainer
			height={height || "1.3em"}
			width={width || "1.3em"}
			path="/assets/images/logo_ideiax_nova.png"
		/>
	);
}

export default Logo;
