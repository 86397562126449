import React from "react";
import {
	EditarPerfilContainer,
	AtualizarGestaoPerfil,
	UploadImageContainer,
	PerfilImagem,
} from "./styles";
import { IUsuarioSimplificado } from "../../../../../shared/dto/UsuarioSimplificado";
import { useLoaderData, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OptionValue } from "../../../../../shared/dto/OptionValue";
import RoleProvider from "../../../../../shared/provider/RoleProvider";
import TenantProvider from "../../../../../shared/provider/TenantProvider";
import UserProvider from "../../../../../shared/provider/UserProvider";
import { UpdateUserDto } from "../../../../../shared/dto/UpdateUserDto";
import validateEditUserUpdateDto from "../../../components/EditarUsuario/components/validation/validateEditUserUpdateDto";
import DefaultButton from "../../../../../shared/util/DefaultButton";
import { FaEnvelope, FaSearch, FaUser } from "react-icons/fa";
import AutoCompleteSelect from "../../../../../shared/util/Autocomplete";
import UserInput from "../../../components/UserInput";
import UploadArea, { FileObject } from "../../../../../shared/util/Upload";
import getLetterColor from "../../../../../shared/util/GetLetterColor";

function EditarGestaoPerfil() {
	const { user } = useLoaderData() as { user: IUsuarioSimplificado };
	const [file, setFile] = React.useState<FileObject | null>(null);
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();

	const [nome, setNome] = React.useState(user.name || "");
	const [email, setEmail] = React.useState(user.email || "");
	const [active, setActive] = React.useState(user.active || false);

	const [roleOptions, setRoleOptions] = React.useState(
		[] as Array<OptionValue>
	);
	const [tenantOptons, setTenantOptions] = React.useState(
		[] as Array<OptionValue>
	);

	const roleProvider = new RoleProvider();
	const tenantProvider = new TenantProvider();
	const userProvider = new UserProvider();

	const [selectedRoleOption, setSelectedRoleOption] = React.useState(
		{} as { value: string; label: string }
	);

	const [selectedTenantOption, setSelectedTenantOption] = React.useState(
		{} as { value: string; label: string }
	);

	const [roleValue, setRoleValue] = React.useState(
		selectedRoleOption.label || ""
	);
	const [tenantValue, setTenantValue] = React.useState(
		selectedTenantOption.label || ""
	);

	async function requireRoleOptions() {
		try {
			const data = await roleProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setRoleOptions(data.data);
			return data.data;
		} catch (error) {
			console.error("Erro ao buscar roles:", error);
			return [] as Array<OptionValue>;
		}
	}

	async function requireTenantOptions() {
		try {
			const data = await tenantProvider.getAll();
			if (!data) return [] as Array<OptionValue>;

			setTenantOptions(data);
			return data;
		} catch (error) {
			console.error("Erro ao buscar tenants:", error);
			return [] as Array<OptionValue>;
		}
	}

	function setSelectsDefaults(roles: OptionValue[], tenants: OptionValue[]) {
		const selectedRole = roles.find((role) => role.label === user.roleName);
		const selectedTenant = tenants.find(
			(tenant) => tenant.label === user.tenantName
		);

		setSelectedRoleOption(
			selectedRole || ({} as { value: string; label: string })
		);
		setSelectedTenantOption(
			selectedTenant || ({} as { value: string; label: string })
		);
	}

	async function onInit() {
		const roles = await requireRoleOptions();
		const tenants = await requireTenantOptions();
		setSelectsDefaults(roles, tenants);
	}

	React.useEffect(() => {
		onInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function updateUser(dto: Partial<UpdateUserDto>) {
		try {
			const data = await userProvider.update(user.id, dto);

			if (!data) return false;
			return true;
		} catch (error) {}
	}

	async function requestUpdate(data: Partial<UpdateUserDto>) {
		setLoading(true);
		const response = await updateUser(data);
		!!file && (await requestImageUpdate(file));

		if (response) {
			toast.success("Alterações salvas com sucesso!");
		}
		setLoading(false);
	}

	async function requestImageUpdate(data: FileObject) {
		setLoading(true);
		await userProvider.uploadProfileImage(user.id, data);
		setLoading(false);
		window.location.reload();
	}

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const nomeCompleto = nome.trim();
		const nomeArray = nomeCompleto.length ? nomeCompleto.split(" ") : [""];
		const nomeUsuario = nomeArray.length > 1 ? nomeArray[0] : "";
		const sobrenomeUsuario =
			nomeArray.length > 1 ? nomeArray.slice(1).join(" ") : "";

		const updatedUser: Partial<UpdateUserDto> = {
			...(nome &&
				nome !== user.name && {
					nome: nomeUsuario,
					sobrenome: sobrenomeUsuario,
				}),
			...(email && email !== user.email && { email }),
			...(active !== user.active && { ativo: active }),
			...(selectedRoleOption.label &&
				selectedRoleOption.label !== user.roleName && {
					roleId: selectedRoleOption.value,
				}),
			...(selectedTenantOption.label &&
				selectedTenantOption.label !== user.tenantName && {
					tenantId: selectedTenantOption.value,
				}),
		};

		const errors = validateEditUserUpdateDto(updatedUser);

		if (errors.length) {
			errors.forEach((error) => toast.error(error));
			return;
		}

		if (!Object.keys(updatedUser).length && !file) {
			toast.warn("Nenhuma alteração foi feita");
			return;
		}

		if (file && !Object.keys(updatedUser).length) {
			requestImageUpdate(file);
		}

		requestUpdate(updatedUser);
	}
	return (
		<EditarPerfilContainer>
			<h1>Editar usuário</h1>

			<UploadImageContainer>
				<PerfilImagem
					className={loading ? "skeleton-loading" : ""}
					letterColor={
						user && user.name ? getLetterColor(user.name.charAt(0)) : ""
					}
					url={
						user.profileImage
							? process.env.REACT_APP_API_PUBLIC_URL + "/" + user.profileImage
							: ""
					}
				>
					{!user.profileImage &&
						user.name &&
						user.name.length > 0 &&
						user.name.charAt(0)}
				</PerfilImagem>

				<div>
					<UploadArea
						file={file}
						setFile={setFile}
						text="Clique aqui ou arraste e solte o arquivo"
					/>
				</div>
			</UploadImageContainer>

			<AtualizarGestaoPerfil onSubmit={handleSubmit}>
				<div className="adicionar-usuario-wrapper">
					<section>
						<UserInput
							value={nome}
							setValue={setNome}
							label="Nome:"
							placeholder="Nome completo"
							message="Digite o nome pelo qual o usuário deseja ser identificado."
							icon={<FaUser />}
							type="username"
						/>

						<UserInput
							value={email}
							setValue={setEmail}
							label="E-mail:"
							placeholder="Ex: seuemail@gov.br"
							message="Digite e-mail corporativo mais utilizado."
							icon={<FaEnvelope />}
							type="email"
						/>
					</section>

					<section>
						<AutoCompleteSelect
							inputValue={roleValue}
							setInputValue={setRoleValue}
							options={roleOptions}
							item={selectedRoleOption}
							setItem={setSelectedRoleOption}
							icon={<FaSearch />}
							showDropdownButton={true}
							label="Perfil:"
							message="O perfil define qual será o tipo de acesso do usuário."
							lockDropdown={false}
						/>

						<AutoCompleteSelect
							inputValue={tenantValue}
							setInputValue={setTenantValue}
							options={tenantOptons}
							item={selectedTenantOption}
							setItem={setSelectedTenantOption}
							icon={<FaSearch />}
							showDropdownButton={true}
							label="Tenants:"
							message="Grupo ao qual este usuário deverá pertencer."
							lockDropdown={false}
						/>
					</section>
				</div>

				<div className="div-divider" />

				<footer>
					<DefaultButton
						fontSize="1em"
						width="250px"
						params={{
							type: "submit",
						}}
					>
						Salvar alterações
					</DefaultButton>

					<DefaultButton
						width="250px"
						fontSize="1em"
						withOutBackground={true}
						params={{
							onClick: () => navigate(`/adm/gestao-usuario/perfil/${user.id}`),
							type: "button",
						}}
					>
						Voltar
					</DefaultButton>
				</footer>
			</AtualizarGestaoPerfil>
		</EditarPerfilContainer>
	);
}

export default EditarGestaoPerfil;
