import React from "react";
import { DimensionListContainer } from "./styles";
import DimensionListItem from "./components/DimensionList";

function DimensionList({
	openAddDimension,
	setOpenAddDimension,
}: Readonly<{
	openAddDimension: boolean;
	setOpenAddDimension: React.Dispatch<React.SetStateAction<boolean>>;
}>): JSX.Element {
	return (
		<DimensionListContainer>
			<DimensionListItem
				openAddDimension={openAddDimension}
				setOpenAddDimension={setOpenAddDimension}
			/>
		</DimensionListContainer>
	);
}

export default DimensionList;
