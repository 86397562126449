import React from "react";
import { AdicionarRoleContainer } from "./styles";
import Modal from "../../../../shared/util/Modal";
import AddRoleForm from "./components/addRoleForm";
import DefaultButton from "../../../../shared/util/DefaultButton";

function AddRole({
	open,
	setOpen,
	requireRoleList,
}: {
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	requireRoleList(): Promise<void>;
}) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	return (
		<AdicionarRoleContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="adicionar-role-modal-container"
				title={!successMessage ? <h2>Criar novo perfíl</h2> : <></>}
				closeButtonCb={!successMessage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMessage && (
					<>
						<AddRoleForm
							setOpen={setOpen}
							isLoading={loading}
							setLoading={setLoading}
							setSuccessMessage={setSuccessMessage}
							requireRoleList={requireRoleList}
						/>
					</>
				)}

				{successMessage && (
					<div className="edit-role-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</AdicionarRoleContainer>
	);
}

export default AddRole;
