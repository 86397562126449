import styled from "styled-components";

export const CheckboxContainer = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 5px 0;
  gap: 10px;
  max-width: 290px;
  width: 100%;
  background: none;
  span {
    font-size: 0.866em;
  }
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 3px;
  cursor: pointer;
  
  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

`;
