import { FaLightbulb, FaUser } from "react-icons/fa";

const MenuItems = [
	{
		title: "Perfíl",
		icon: <FaUser />,
		disabled: false,
	}
];

export default MenuItems;
