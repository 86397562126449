import React from "react";
import { CategoryListContainer } from "./styles";
import CategoryListItem from "./components/CategoryList";

function CategoryList({
	openAddCategory,
	setOpenAddCategory,
}: Readonly<{
	openAddCategory: boolean;
	setOpenAddCategory: React.Dispatch<React.SetStateAction<boolean>>;
}>): JSX.Element {
	return (
		<CategoryListContainer>
			<CategoryListItem
				openAddCategory={openAddCategory}
				setOpenAddCategory={setOpenAddCategory}
			/>
		</CategoryListContainer>
	);
}

export default CategoryList;
