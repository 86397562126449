import React, { useEffect, useState } from "react";
import { Slide, SlideshowContainer } from "./styles";

interface SlideShowData {
	path: string;
	title: string;
	caption: string | JSX.Element;
}

interface SlideShowProps {
	slides: SlideShowData[];
}

function SlideShow({ slides }: SlideShowProps): JSX.Element {
	const [currentSlide, setCurrentSlide] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide((currentSlide) =>
				currentSlide === slides.length - 1 ? 0 : currentSlide + 1
			);
		}, 3000);

		return () => clearInterval(interval);
	}, [slides.length]);

	return (
		<SlideshowContainer>
			{slides.map((slide, index) => (
				<Slide
					key={index}
					backgroundImage={slide.path}
					isActive={index === currentSlide}
				>
					<h2>{slide.title}</h2>
					<span>{slide.caption}</span>
				</Slide>
			))}
		</SlideshowContainer>
	);
}

export default SlideShow;
