import validator from 'validator';
import { UpdateUserDto } from '../../../../../../shared/dto/UpdateUserDto';

function validateEditUserUpdateDto(
  { email, tenantId, roleId}:
  Partial<UpdateUserDto>) {
  const errors = [];

    if (email && !validator.isEmail(email)) {
      errors.push('O email não é válido');
    }
    if (tenantId && !validator.isUUID(tenantId)) {
      errors.push('O tenant não é válido');
    }
    if (roleId && !validator.isUUID(roleId)) {
      errors.push('A função não é válida');
    }
    return errors;
  }

  export default validateEditUserUpdateDto;