import styled from "styled-components";
import { ToastContainer } from "react-toastify";

export const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    margin-top: 15%;
    background: transparent;
  }
  .Toastify__toast {
    border-radius: 0;
    background-color: #e3f5e1;
    width: 450px;
    padding: 20px 10px;
  }
  .Toastify__toast-body {
    border-radius: 0;
    background-color: #e3f5e1;
    font-size: 1.1em;
    * {
      background-color: #e3f5e1;
    }
  }
  .Toastify__progress-bar {
  }
`;