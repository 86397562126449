import React from "react";
import {ExcluirCategoryContainer} from "./styles";
import CategoryProvider from "../../../../../../shared/provider/CategoryProvider";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import {CategoryDto} from "../../../../../../shared/dto/CategoryDto";

function DeleteCategory({
                            open,
                            setOpen,
                            category,
                            onFinished,
                        }: Readonly<{
    open: boolean;
    setOpen: React.Dispatch<boolean>;
    category: CategoryDto;
    onFinished?: () => void;
}>) {
    const [successMessage, setSuccessMessage] = React.useState("");
    const categoryProvider = new CategoryProvider();
    React.useEffect(() => {
        if (!open) {
            setSuccessMessage("");
        }
    }, [open]);

    async function handleExcluirCategory() {
        if (category) {
            try {
                await categoryProvider.delete(category.categoryId);
                setSuccessMessage("Categoria excluida com sucesso.");
            } catch (error) {
                console.error("Erro ao excluir categoria:", error);
            }
        }
    }

    return (
        <ExcluirCategoryContainer>
            <Modal
                open={open}
                setOpen={setOpen}
            >
                {!successMessage && (
                    <>
                        <h3 style={{textAlign: "center"}}>Excluir categoria "{category.name}"?</h3>

                        <footer>
                            <DefaultButton
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                withOutBackground={true}
                                params={
                                    {
                                        onClick: () => setOpen(false),
                                    }
                                }
                            >
                                Voltar
                            </DefaultButton>
                            <DefaultButton
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                params={{
                                    onClick: handleExcluirCategory,
                                }}
                            >
                                Excluir
                            </DefaultButton>
                        </footer>
                    </>
                )}

                {successMessage && (
                    <div className="edit-user-success-step">
                        <h2>{successMessage}</h2>
                        <div>
                            <DefaultButton
                                withOutBackground={true}
                                width="115px"
                                fontSize="1em"
                                height="36px"
                                params={{
                                    onClick: () => {
                                        setOpen(false);
                                        onFinished && onFinished();
                                    },
                                }}
                            >
                                Fechar
                            </DefaultButton>
                        </div>
                    </div>
                )}
            </Modal>
        </ExcluirCategoryContainer>
    );
}

export default DeleteCategory;
