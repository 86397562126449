const customLocale = {
  filterTitle: 'Filtro',
  filterConfirm: 'Aplicar',
  filterReset: 'Resetar',
  emptyText: 'Nenhum dado disponível',
  selectAll: 'Selecionar tudo na página',
  selectInvert: 'Inverter seleção na página',
  expand: 'Expandir',
  collapse: 'Colapsar',
  triggerDesc: 'Clique para ordenar decrescente',
  triggerAsc: 'Clique para ordenar crescente',
  cancelSort: 'Cancelar ordenação',
};

export default customLocale;