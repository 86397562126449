import React from "react";
import { UserInputContainer, UserInputElement } from "./styles";

interface UserInputProps {
	label?: string;
	value: string;
	setValue: React.Dispatch<string>;
	placeholder?: string;
	icon?: JSX.Element;
	message?: string;
	type?: string;
	width?: string;
	isLoading?: boolean;
	id?: string;
	readonly?: boolean;
	isDocument?: boolean;
	regex?: RegExp;
}

function UserInput(props: UserInputProps): JSX.Element {
	const [value, setValue] = React.useState(props.value ?? "");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!props.isDocument) {
			let valueFiltered = e.target.value;
			if (props.regex) {
				valueFiltered = e.target.value.replace(props.regex, "");
			}
			setValue(valueFiltered);
			props.setValue(valueFiltered);
			return;
		}

		let { value } = e.target;
		value = value.replace(/\D/g, "");

		if (value.length > 11) {
			value = value.slice(0, 11);
		}

		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
		setValue(value);
		props.setValue(value);
	};

	return (
		<UserInputContainer
			width={props.width}
			id={props.id}
			readOnly={props.readonly}
		>
			{props.label && <span className="input-label">{props.label}</span>}

			<div
				className={`user-input-wrapper ${props.isLoading ? "skeleton-loading" : ""}`}
			>
				{props.icon && props.icon}
				<UserInputElement
					type={props.type || "text"}
					placeholder={props.placeholder}
					value={value}
					//onChange={(e) => props.setValue(e.target.value)}
					onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleInputChange(e)
					}
					readOnly={props.readonly}
				/>
			</div>
			{props.message && <span className="input-message">{props.message}</span>}
		</UserInputContainer>
	);
}

export default UserInput;
