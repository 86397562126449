import React from "react";
import { SwitchContainer } from "./styles";

interface SwitchProps {
	isOn: boolean;
	handleToggle: React.Dispatch<boolean>;
	isOnIcon?: JSX.Element;
	width?: string;
	isLoading?: boolean;
}

function Switch({
	isOn,
	handleToggle,
	isOnIcon,
	width,
	isLoading,
}: SwitchProps): JSX.Element {
	function handleOnClick() {
		handleToggle(!isOn);
	}

	return (
		<SwitchContainer width={width}>
			<input
				checked={isOn}
				onChange={handleOnClick}
				className="react-switch-checkbox"
				id={`react-switch-new`}
				type="checkbox"
				disabled={isLoading}
			/>
			<label
				className={`react-switch-label ${isLoading ? "skeleton-loading" : ""}`}
				htmlFor={`react-switch-new`}
			>
				<span
					aria-checked={isOn}
					className={`react-switch-button ${isLoading ? "skeleton-loading" : ""}`}
				>
					{isOnIcon && isOn ? isOnIcon : ""}
				</span>
			</label>
		</SwitchContainer>
	);
}

export default Switch;
