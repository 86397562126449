import styled from "styled-components";

export const PaginationContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 20px 2%;

  select {
    background-color: ${({ theme }) => theme.colors.background};
  }

  .pagination-controller {
    transition: all 0.2s ease-in-out;
    gap: 20px;
    svg {
      fill: ${({ theme }) => theme.colors.primary};
      cursor: pointer;
    }

    svg[aria-disabled="true"] {
      fill: ${({ theme }) => theme.colors.lightGray};
      cursor: not-allowed;
      user-select: none;
    }
  }

  div {
    display: flex;
    font-size: 0.933em;

    div {
      display: flex;
      align-items: center;
    }
    
    div:first-child {
      border-right: 1px solid #e0e0e0;
      padding-right: 15px;

      select {
        border: 0;
        font-size: 1.2em;
        margin-left: 15px;
        font-weight: 600;
      }
    }

    div:last-child {
      padding-left: 15px;
    }
  }
`