import validator from 'validator';

function validateUserCreationDto(
  { name, email, tenantId, roleId, document }:
  { name: string, email: string, tenantId: string, roleId: string, document?: string}) {
  const errors = [];

  const nameParts = name.split(' ');
    if (nameParts.length < 2) {
      errors.push('O nome deve ser completo');
    }
    if (!validator.isEmail(email)) {
      errors.push('O email não é válido');
    }
    if (!validator.isUUID(tenantId)) {
      errors.push('O tenant não é válido');
    }
    if (!validator.isUUID(roleId)) {
      errors.push('A função não é válida');
    }
    if (document && !isValidCPF(document)) {
      errors.push('O CPF não é válido');
    }

    return errors;
  }

  function isValidCPF(cpf: string) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    let cpfArray = cpf.split('').map(el => +el)
    const rest = (count: number) => (cpfArray.slice(0, count-12)
        .reduce( (soma, el, index) => (soma + el * (count-index)), 0 )*10) % 11 % 10
    return rest(10) === cpfArray[9] && rest(11) === cpfArray[10]
}

  export default validateUserCreationDto;