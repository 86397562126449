import styled from "styled-components";

export const SearchInputContainer = styled.form`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.softGray};
  max-width: 450px;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;

  button {
    background: none;
    border: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover {
      background: ${({ theme }) => theme.colors.lightGray};
    }
    svg {
      background: none;
      font-size: 1.2em;
      path {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const SearchInputField = styled.input`
  background: none;
  border: none;
  outline: none;
  width: 95%;
  color: ${({ theme }) => theme.colors.text};
  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;