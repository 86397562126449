import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { StyledContainer } from "./styles";

function NotifyContainer() {
	return (
		<StyledContainer
			position={"top-center"}
			autoClose={2000}
			hideProgressBar={true}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			style={{}}
		/>
	);
}

export default NotifyContainer;
