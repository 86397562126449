import React from "react";
import { TabMenuContainer } from "./styles";
import MenuItems from "./config/MenuItems";
import PermissionValidator from "../../../../guard/PermissionValidator";
import { NavLink, useNavigate } from "react-router-dom";

function TabMenu(): JSX.Element {
	const permissions = new PermissionValidator();
	const navigate = useNavigate();

	return (
		<TabMenuContainer>
			<ul>
				{MenuItems.map((item) => {
					if (item.protected && !permissions.hasAccess(item.protected)) {
						return null;
					}
					return (
						<li
							aria-disabled={item.disabled}
							key={item.path}
						>
							{item.disabled && <a href="#">{item.title}</a>}
							{!item.disabled && (
								<NavLink
									to={item.path}
									className={({ isActive }) =>
										isActive ? "active-nav-tab-item" : ""
									}
								>
									{item.icon}
									{item.title}
								</NavLink>
							)}
						</li>
					);
				})}
			</ul>
		</TabMenuContainer>
	);
}

export default TabMenu;
