import React from "react";
import { TenantModalContainer } from "./styles";
import Modal from "../Modal";
import AutoCompleteSelect from "../Autocomplete";
import { OptionValue } from "../../dto/OptionValue";
import TenantProvider from "../../provider/TenantProvider";
import { FaSearch } from "react-icons/fa";
import DefaultButton from "../DefaultButton";
import UserProvider from "../../provider/UserProvider";
import AuthProvider from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";

interface TenantModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

function TenantModal({ open, setOpen }: TenantModalProps): JSX.Element {
	const [loading, setLoading] = React.useState(false);
	const [tenantOptions, setTenantOptions] = React.useState(
		[] as Array<OptionValue>
	);
	const [selectedTenantOption, setSelectedTenantOption] = React.useState(
		{} as { value: string; label: string }
	);
	const [tenantValue, setTenantValue] = React.useState(
		selectedTenantOption.label || ""
	);

	const navigate = useNavigate();
	const tenantProvider = new TenantProvider();
	const authProvider = new AuthProvider();

	async function requireTenantOptions() {
		try {
			const data = await tenantProvider.getFromLoggedUser();
			if (!data) return [] as Array<OptionValue>;

			setTenantOptions(data);
			return data;
		} catch (error) {
			console.error("Erro ao buscar tenants:", error);
			return [] as Array<OptionValue>;
		}
	}

	function setSelectsDefaults(tenants: OptionValue[]) {
		const selectedTenant = tenants.find(
			(tenant) => tenant.label === window.localStorage.getItem("tenantName")
		);

		setSelectedTenantOption(
			selectedTenant || ({} as { value: string; label: string })
		);
	}

	async function onInit() {
		const tenants = await requireTenantOptions();
		setSelectsDefaults(tenants);
	}

	React.useEffect(() => {
		onInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleLogout() {
		try {
			await authProvider.logout();
			navigate("/entrar");
		} catch (error) {
			console.error("Erro ao fazer logout:", error);
		}
	}

	function handleVoltarParaLogin() {
		handleLogout();
		setTimeout(() => setOpen(false), 1000);
	}

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setLoading(true);
		try {
			const response = await authProvider.selectTenant(
				selectedTenantOption.value
			);
			if (response) {
				setOpen(false);
			}
		} catch (error) {
			console.error("Erro ao selecionar tenant:", error);
		}
		setLoading(false);
	}

	return (
		<Modal
			open={open}
			setOpen={setOpen}
			disableClickOutside={true}
		>
			<TenantModalContainer onSubmit={handleSubmit}>
				<h1>Selecione o grupo</h1>

				<div>
					<AutoCompleteSelect
						inputValue={tenantValue}
						setInputValue={setTenantValue}
						options={tenantOptions}
						item={selectedTenantOption}
						setItem={setSelectedTenantOption}
						icon={<FaSearch />}
						showDropdownButton={true}
						label="Tenants:"
						isLoading={tenantOptions.length === 0 || loading}
					/>
				</div>

				<footer>
					<DefaultButton
						width="140px"
						height="35px"
						fontSize="0.9em"
						params={{
							type: "button",
							onClick: () => handleVoltarParaLogin(),
						}}
						withOutBackground={true}
					>
						Voltar para o login
					</DefaultButton>

					<DefaultButton
						width="140px"
						height="35px"
						fontSize="0.9em"
						params={{
							type: "submit",
							disabled: loading,
						}}
					>
						Selecionar
					</DefaultButton>
				</footer>
			</TenantModalContainer>
		</Modal>
	);
}

export default TenantModal;
