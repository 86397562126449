import React from "react";
import { PaginationContainer } from "./styles";
import { IPageData } from "../../dto/PageData";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface IPaginationProps {
	data: IPageData<any>;
	request(page: number, itemsPerPage: number): Promise<void>;
}
function Pagination(props: IPaginationProps) {
	const [perPage, setPerPage] = React.useState(10);

	React.useEffect(() => {
		if (props.data.itemsPerPage && props.data.itemsPerPage !== perPage) {
			setPerPage(props.data.itemsPerPage);
		}
	}, [props.data.itemsPerPage]);

	async function requireUserList(page: number, perPage: number) {
		try {
			await props.request(page, perPage);
		} catch (error) {
			console.error(error);
		}
	}

	function handleOnChangePerPage(e: React.ChangeEvent<HTMLSelectElement>) {
		if (e.target.value && Number(e.target.value) === perPage) return;
		setPerPage(Number(e.target.value));
		requireUserList(1, Number(e.target.value));
	}

	function handleOnChangePage(e: React.ChangeEvent<HTMLSelectElement>) {
		if (e.target.value && props.data.currentPage === Number(e.target.value))
			return;
		requireUserList(Number(e.target.value), perPage);
	}

	function handleNextPage() {
		if (
			props.data.currentPage &&
			props.data.currentPage < props.data.totalPages
		) {
			requireUserList(props.data.currentPage + 1, perPage);
		}
	}

	function handlePreviousPage() {
		if (props.data.currentPage && props.data.currentPage > 1) {
			requireUserList(props.data.currentPage - 1, perPage);
		}
	}

	function RenderPerPageOptions() {
		if (perPage && props.data.itemsPerPage && props.data.totalItems) {
			const options = [5];
			for (let i = 10; i <= props.data.totalItems; i += 10) {
				options.push(i);
			}
			return (
				<select
					value={perPage}
					onChange={handleOnChangePerPage}
				>
					{options.map((option) => (
						<option value={option}>{option}</option>
					))}
				</select>
			);
		}
	}

	function RenderPageOptions() {
		if (props.data.totalPages && props.data.currentPage) {
			const options = Array.from(
				{ length: props.data.totalPages },
				(_, i) => i + 1
			);
			return (
				<select
					value={props.data.currentPage}
					onChange={handleOnChangePage}
				>
					{options.map((option) => (
						<option value={option}>{option}</option>
					))}
				</select>
			);
		}
	}

	return (
		<PaginationContainer>
			<div>
				<div>
					Exibir <RenderPerPageOptions />{" "}
				</div>
				<div>
					{perPage && props.data.itemsPerPage && props.data.totalItems ? (
						<>
							<span>
								1-{perPage} de {props.data.totalItems}{" "}
								{`ite${props.data.totalItems > 1 ? "ns" : "m"}`}
							</span>
						</>
					) : null}
				</div>
			</div>
			<div>
				<div>
					Página <RenderPageOptions />
				</div>
				<div className="pagination-controller">
					<FaChevronLeft
						title="Voltar"
						onClick={handlePreviousPage}
						aria-disabled={
							props.data.currentPage && props.data.currentPage === 1
								? true
								: false
						}
					/>
					<FaChevronRight
						title="Avançar"
						onClick={handleNextPage}
						aria-disabled={
							props.data.currentPage &&
							props.data.totalPages &&
							props.data.currentPage === props.data.totalPages
								? true
								: false
						}
					/>
				</div>
			</div>
		</PaginationContainer>
	);
}

export default Pagination;
