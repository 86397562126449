import styled from "styled-components";

export const LoginContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 800px) {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
`;
