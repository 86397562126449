import styled from "styled-components";

export const AddStageFormContainer = styled.form`
    section {
        margin: 15px 0;
    }

    h2 {
        text-align: center;
    }

    footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        width: 100%;
        gap: 15px;
    }
`;

export const AddDivider = styled.div`
  margin: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const AddStageCriteria = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;