import React from "react";
import {
    ShowDimensionContainer,
    ShowDimensionDescription,
} from "./styles";
import DefaultButton from "../../../../../../../shared/util/DefaultButton";
import {DimensionDto} from "../../../../../../../shared/dto/DimensionDto";
import {List, Tooltip} from "antd";

function ShowDimension({
                           setOpen,
                           setEditMode,
                           handleDeleteDimensionClick,
                           dimension,
                       }: {
    readonly setOpen: React.Dispatch<boolean>;
    readonly setEditMode: React.Dispatch<boolean>;
    readonly handleDeleteDimensionClick: (dimension: DimensionDto) => void;
    readonly dimension: DimensionDto;
}) {
    return (
        <ShowDimensionContainer>
            <ShowDimensionDescription>{dimension.description}</ShowDimensionDescription>

            {dimension.categories && dimension.categories.length > 0 && (
                <List
                    header={<b>Critérios</b>}
                    bordered
                    dataSource={dimension.categories}
                    renderItem={(item) => (
                        <List.Item
                            style={{
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                                width: "100%"
                            }}
                        >
                            <Tooltip
                            zIndex={999999} title="Nome">{item.title}</Tooltip>
                        </List.Item>
                    )}
                />
            )}

            <footer>
                <DefaultButton
                    width="125px"
                    height="36px"
                    fontSize="1em"
                    withOutBackground={true}
                    params={{
                        onClick: () => setOpen(false),
                    }}
                >
                    Voltar
                </DefaultButton>
                <div className="double-button">
                    <DefaultButton
                        width="150px"
                        height="36px"
                        fontSize="1em"
                        withOutBackground={true}
                        params={{
                            onClick: () => handleDeleteDimensionClick(dimension),
                        }}
                    >
                        Excluir
                    </DefaultButton>
                    <DefaultButton
                        width="150px"
                        height="36px"
                        fontSize="1em"
                        params={{
                            type: "button",
                            onClick: () => setEditMode(true),
                        }}
                    >
                        Editar
                    </DefaultButton>
                </div>
            </footer>
        </ShowDimensionContainer>
    );
}

export default ShowDimension;
