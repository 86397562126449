import styled from "styled-components";

export const EditUserFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 700px;
  overflow: hidden;
  .react-switch-bg {
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
  }

  .user-input-wrapper {
    max-width: none;
  }
  
  .autocomplete-selects {
    max-width: none;
  }

  .autocomplete-form {
    max-width: none;
    width: 100%;
  }

  .double-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  #editar-perfil-ativo {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
    
  }

  .ver-perfil-completo-button {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    a {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1em;
      text-decoration: none;
    }
  }
`;

export const EditarFormCheckboxContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.softGray};
  padding: 15px;
  h3 {
    font-size: 1em;
    background: none;

  }
  p {
    font-size: 0.833em;
    background: none;
  }


  
  section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: transparent;
    margin-top: 12px;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      font-size: 0.9em;
      gap: 8px;
      background: none;
      li span {
        background: none;

      }
    }
  }
`;

export const EditarFormDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 2% 0;
`;

export const EditarFormButtonsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media(max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;

      button {
        max-width: none;
        width: 100%;
      }

      &:first-child {
        width: 100%;
        margin-top: 15px;
        button {
          width: 100% !important;
        }
      }
    }

  }

  div {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    width: 100%;
  }
`;