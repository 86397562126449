import React, { useEffect, useState } from "react";
import {
	DimensionLine,
	DimensionListContainer,
	DimensionListContent,
	DimensionListHeader,
	DimensionThumbnail,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderTitle from "./config/HeaderTitle";
import { FaSortAlphaDown, FaSortAlphaUp, FaTrash } from "react-icons/fa";
import { DimensionDto } from "../../../../../../shared/dto/DimensionDto";
import { IPageData } from "../../../../../../shared/dto/PageData";
import Pagination from "../../../../../../shared/util/Pagination";
import sortList from "../../../../../../shared/util/Order";
import DimensionProvider from "../../../../../../shared/provider/DimensionProvider";
import AddDimension from "../AddDimension";
import EditDimension from "../EditDimension";
import DeleteDimension from "../DeleteDimension";
import { Table } from "antd";
import { DimensionItem } from "../../../../../../shared/dto/DimensionItem";
import columns from "./config/columns";
import customLocale from "../../../../../../shared/constants/customLocale";
import mapDimensionDtoListToItems from "../../utils/mapDtoToItem";

function DimensionListItem({
	openAddDimension,
	setOpenAddDimension,
}: {
	readonly openAddDimension: boolean;
	readonly setOpenAddDimension: React.Dispatch<boolean>;
}): JSX.Element {
	const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">();
	const [sortKey, setSortKey] = React.useState<string>();
	const [editDimension, setEditDimension] = React.useState(false);
	const [deleteDimension, setDeleteDimension] = React.useState(false);
	const [selectedDimensionForEdit, setSelectedDimensionForEdit] = React.useState(
		{} as DimensionDto
	);
	const [selectedDimensionForDelete, setSelectedDimensionForDelete] =
		React.useState({} as DimensionDto);

	const [response, setResponse] = useState<IPageData<Array<DimensionDto>>>(
		{} as IPageData<Array<DimensionDto>>
	);
	const [list, setList] = useState<DimensionItem[]>([]);

	const dimensionProvider = new DimensionProvider();
	const navigate = useNavigate();

	function getPreviousSelectedDimensionForEdit(data: DimensionDto[]) {
		if (!data) return;
		const dimension = data.find(
			(cat) => cat.dimensionId === selectedDimensionForEdit.dimensionId
		);
		if (dimension) setSelectedDimensionForEdit(dimension);
	}

	async function requireDimensionList(page = 1, itemsPerPage = 10) {
		try {
			const data = await dimensionProvider.getAll({
				page,
				itemsPerPage,
			});
			if (!data) return;

			setResponse(data);
			setList(mapDimensionDtoListToItems({
				dto: data.data,
				deleteFn: handleDeleteDimensionClick,
				viewFn: handleEditDimensionClick,
			}));
			getPreviousSelectedDimensionForEdit(data.data);
		} catch (error) {
			toast(
				<span>
					<b>Sessão expirada.</b> Você será redirecionado!
				</span>,
				{
					type: "error",
					autoClose: 500,
					onClose: () => {
						navigate("/entrar");
					},
				}
			);
		}
	}

	useEffect(() => {
		requireDimensionList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function handleEditDimensionClick(dimension: DimensionDto) {
		setSelectedDimensionForEdit(dimension);
		setEditDimension(true);
	}

	function handleDeleteDimensionClick(dimension: DimensionDto) {
		setSelectedDimensionForDelete(dimension);
		setDeleteDimension(true);
		setEditDimension(false);
	}

	function handleSortList() {
		if (sortKey) {
			const direction = sortDirection === "asc" ? "desc" : "asc";
			const sorted = sortList(
				response.data,
				sortKey as keyof DimensionDto,
				direction
			);
			setResponse({ ...response, data: sorted });
		}
	}

	React.useEffect(() => {
		handleSortList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection, sortKey]);

	return (
		<DimensionListContainer>
			<AddDimension
				open={openAddDimension}
				setOpen={setOpenAddDimension}
				requireDimensionList={requireDimensionList}
			/>
			<EditDimension
				open={editDimension}
				setOpen={setEditDimension}
				requireDimensionList={requireDimensionList}
				dimension={selectedDimensionForEdit}
				handleDeleteDimensionClick={handleDeleteDimensionClick}
			/>
			<DeleteDimension
				open={deleteDimension}
				setOpen={setDeleteDimension}
				dimension={selectedDimensionForDelete}
				onFinished={requireDimensionList}
			/>

			<DimensionListHeader></DimensionListHeader>

				<Table<DimensionItem>
					columns={columns}
					dataSource={list || []}
					locale={customLocale}
					pagination={{defaultPageSize: 5}}
					scroll={{ x: 920 }}
					/>
		</DimensionListContainer>
	);
}

export default DimensionListItem;
