import { CategoryDto } from '../dto/CategoryDto';
import { CreateCategoryDto } from '../dto/CreateCategoryDto';
import { OptionValue } from '../dto/OptionValue';
import { IPageData } from '../dto/PageData';
import { UpdateCategoryDto } from '../dto/UpdateCategoryDto';
import Envoy from '../util/Envoy';

interface IPaginationDTO {
  page?: number
  itemsPerPage?: number
}

class CategoryProvider extends Envoy {
  public async getAll(
    {page,
    itemsPerPage,}
      : IPaginationDTO): Promise<IPageData<Array<CategoryDto>>> {
      try {
        const paginationString = this.buildQueryString({page, itemsPerPage});

        const response = await this.conn.get(`/category${paginationString}`);
        
        if (response.data.messages?.length)
          throw new Error(response.data.messages.join('\n'));

        return response.data.data;
      } catch (error) {
        console.error('Erro ao obter todas as categorias:', error);
        throw error;
      }
    }

  public async create(category: CreateCategoryDto): Promise<CategoryDto> {
    try {
      const response = await this.conn.post('/category', category);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao criar categoria:', error);
      throw error;
    }
  }

  public async saveCoverImage(categoryId: string, image: File): Promise<void> {
    try {
      const formData = new FormData();
      formData.append('file', image);

      const response = await this.conn.post(`/category/${categoryId}/coverImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao salvar imagem de capa:', error);
      throw error;
    }
  }

  public async deleteCoverImage(categoryId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/category/${categoryId}/coverImage`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar imagem de capa:', error);
      throw error;
    }
  }

  public async update(category: UpdateCategoryDto): Promise<CategoryDto> {
    try {
      const response = await this.conn.put(`/category/${category.categoryId}`, category);
      
      if (response.data.messages?.length)
        throw new Error(response.data.messages.join('\n'));
      return response.data.data;
    } catch (error) {
      console.error('Erro ao atualizar categoria:', error);
      throw error;
    }
  }

  public async delete(categoryId: string): Promise<void> {
    try {
      const response = await this.conn.delete(`/category/${categoryId}`);
      if (response.data.messages?.length) {
        throw new Error(response.data.messages.join('\n'));
      }
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
      throw error;
    }
  }
  
}

export default CategoryProvider;