import React from "react";
import { AdicionarDimensionContainer } from "./styles";
import Modal from "../../../../../../shared/util/Modal";
import DefaultButton from "../../../../../../shared/util/DefaultButton";
import AddDimensionForm from "./components/addDimensionForm";

function AddDimension({
	open,
	setOpen,
	requireDimensionList,
}: Readonly<{
	open: boolean;
	setOpen: React.Dispatch<boolean>;
	requireDimensionList(): Promise<void>;
}>) {
	const [loading, setLoading] = React.useState(false);
	const [successMessage, setSuccessMessage] = React.useState("");

	React.useEffect(() => {
		if (!open) {
			setSuccessMessage("");
		}
	}, [open]);

	return (
		<AdicionarDimensionContainer>
			<Modal
				open={open}
				setOpen={setOpen}
				containerClassName="adicionar-dimension-modal-container"
				title={!successMessage ? <h2>Criar Dimensão</h2> : <></>}
				closeButtonCb={!successMessage ? () => setOpen(false) : undefined}
				disableClickOutside={true}
			>
				{!successMessage && (
					<AddDimensionForm
						setOpen={setOpen}
						isLoading={loading}
						setLoading={setLoading}
						setSuccessMessage={setSuccessMessage}
						requireDimensionList={requireDimensionList}
					/>
				)}

				{successMessage && (
					<div className="edit-dimension-success-step">
						<h2>{successMessage}</h2>
						<div>
							<DefaultButton
								withOutBackground={true}
								width="125px"
								fontSize="1em"
								height="36px"
								params={{
									onClick: () => setOpen(false),
								}}
							>
								Fechar
							</DefaultButton>
						</div>
					</div>
				)}
			</Modal>
		</AdicionarDimensionContainer>
	);
}

export default AddDimension;
