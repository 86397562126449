import React from "react";
import { GestaoUsuarioContainer } from "./styles";
import UserList from "../components/UserList";
import DefaultButton from "../../../shared/util/DefaultButton";
import { FaPlus } from "react-icons/fa";

function GestaoUsuario(): JSX.Element {
	//const navigate = useNavigate();
	const [openAddUser, setOpenAddUser] = React.useState(false);

	return (
		<>
			<GestaoUsuarioContainer>
				<div className="header-criar-usuario">
					<DefaultButton
						withOutBackground={true}
						width="275px"
						height="35px"
						fontSize="1em"
						params={{
							onClick: () => setOpenAddUser(true),
						}}
					>
						<FaPlus />
						<span>Cadastrar novo usuário</span>
					</DefaultButton>
				</div>

				<h1>Gestão de usuários</h1>

				<UserList
					openAddUser={openAddUser}
					setOpenAddUser={setOpenAddUser}
				/>
			</GestaoUsuarioContainer>
		</>
	);
}
export default GestaoUsuario;
