import styled from "styled-components";

export const UserListContainer = styled.div`
  margin: 2% 4%;
  @media(max-width: 850px) {
    overflow: scroll;
  }
  .orderButton {
    cursor: pointer;
    margin-left: 5px;
    background: none;

    svg {
      background: none;
    }
  }
`;

export const UserListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 500;
  }
  div {
    display: flex;
  }

`;
export const UserListContent = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 850px;
  
  tbody {
    flex-direction: column;
  }

  tbody, thead {
    display: flex;
    td {
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 0.833em;
    }
    td[data-name="userName"] {
      flex-grow: 3;
      width: 100%;
      min-width: 250px;
      span {
        max-width: 200px;
        width: 100%;
        flex-shrink: 0;
        background: none;
        overflow: hidden; 
        text-overflow: ellipsis;
      }
    }
    td[data-name="userEmail"] {
      flex-grow: 2;
      max-width: 250px;
      min-width: 200px;
      width: 100%;
    }
    td[data-name="userTenant"] {
      flex-grow: 1;
      max-width: 170px;
      min-width: 90px;
      width: 100%;
    }
    td[data-name="userCreatedAt"] {
      flex-grow: 1;
      max-width: 150px;
      min-width: 100px;
      width: 100%;
    }
    td[data-name="userRole"] {
      flex-grow: 2;
      max-width: 250px;
      min-width: 120px;
      width: 100%;
    }
    td[data-name="userActions"] {
      flex-grow: 1;
      max-width: 150px;
      width: 100%;
    }
    .td-action {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
    .th-style {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }

  .listing-header {
    width: 100%;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.lightGray};
    height: 60px;
    padding: 0 20px;
    th {
      background-color: ${({ theme }) => theme.colors.lightGray};
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }
  `;

export const UserListImage = styled.div<{ image?: string, letterColor: string }>`
  background: ${({ image, letterColor }) => (image ? `url(${image})` : letterColor)};
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.background};
  user-select: none;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
`;

export const UserLine = styled.tr`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.lightGray};
  transition: background 0.3s ease-in-out;

  &:last-child {
    border-bottom: none;
  }

  td {
    transition: background 0.3s ease-in-out;
  }

  &:hover {
      background-color: ${({ theme }) => theme.colors.softGray};
      td {
        background-color: ${({ theme }) => theme.colors.softGray};
      }
  }

  .user-list-editar-button {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    opacity: 0.9;
    background: ${({ theme }) => theme.colors.softGray};
    padding: 5px 15px;
    border-radius: 20px;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`;