import styled from "styled-components";

export const LoginBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
  justify-content: space-between;
	flex: 1;
  margin-left: 4%;
  height: 100vh;
  min-width: 300px;

  @media(max-width: 750px) {
    margin: 0 20px;
  }
`;

export const LoginBoxHeader = styled.section`
  h2 {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    margin-top: 100px;
    font-size: 1.4em;

    @media(max-width: 750px) {
      margin-bottom: 65px;
    }
  }
`;

export const LoginBoxContent = styled.section`

  h3 {
    font-size: 1.5em;
    line-height: 2em;
    transition: all 500ms ease-in-out;
  }
  p {
    margin-top: 5%;
    transition: all 500ms ease-in-out;
    font-size: 1.1em;
  }
  
  @media(max-width: 500px) {
    h3 {
      font-size: 1.2em;
    }
    p {
      font-size: 1em;
    }
  }
`;

export const LoginBoxFooter = styled.section`
  display: flex;
  gap: 4%;
  margin: 5% 0;
  font-size: 1.1em;
  transition: all 500ms ease-in-out;
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
  }

  @media(max-width: 750px) {
      margin-top: 100px;
  }

  @media(max-width: 500px) {
      font-size: 1em;
  }
`;